import styled from "styled-components";

export const MeetContainer = styled.div`
   width: 100%;
   min-height: calc(95vh);
   background-color: #eee;

   display: flex;
   justify-content: center;
   align-items: center;
   padding-bottom: 2rem;
   padding-top: 2rem;
`

export const MeetSubContainer = styled.div`
   width: 85%;
   height: 100%;
   display: flex;
   
   justify-content: space-between;
   border-radius: 5px;
`

export const MeetAssetEventContainer = styled.div`
   height: 800px;
   width: 25%;
   display: flex;
   flex-direction: column;
`

export const MeetAssetContainer = styled.div`
   width: 100%;
   /* height: 50%; */
   background-color: white;
   border-radius: 5px;
`

export const MeetEventContainer = styled.div`
   margin-top: 1rem;
   background-color: white;
   width: 100%;
   /* height: 50%; */
   border-radius: 5px;
`

export const MeetMemberContainer = styled.div`
   height: 800px;
   width: 74%;
   border-radius: 5px;
   background-color: white;
`