import styled from "styled-components";

export const InputContainer = styled.div`
   width: 450px;
   height: 100px;
   display: flex;
   align-items: center;
   justify-content: space-between;
`

export const Input = styled.input`
   width: 60px;
   height: 60px;
   outline: none;
   border: 3px solid black;
   border-radius: 5px;
   font-size: 3rem;
   text-align: center;

   &:focus{
      border: 3px solid var(--green);
      border-radius: 2px;
   }
`