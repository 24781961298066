import styled from "styled-components";

export const CalendarContainer = styled.div`
   width: 100%;
   height: 100%;
   background-color: white;
   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
   border-radius: 5px;

   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
`

export const CalendarAvatarTextContainer = styled.div`
   width: 100%;
   height: 20%;

   display: flex;
   justify-content: space-evenly;
`

export const CalendarAvatarContainer = styled.div`
   width: 30%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
`

export const CalendarAvatar = styled.img`
   
`

export const CalendarPickerContainer = styled.div`
   width: 85%;
   height: 60%;

   box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
   padding: 15px;
`

export const CalendarTextContainer = styled.div`
   height: ${props => props.heigh};
   width: ${props => props.wide};
   display: flex;
   flex-direction: column;
   justify-content: center;
`

export const CalendarHeadingContainer = styled.div`
   width: 100%;
   margin-bottom: 0.5rem;
`

export const CalendarHeading = styled.h2`
   font-size: 2rem;
   font-weight: bold;
`

export const CalendarDescContainer = styled.div`
   
`

export const CalendarDesc = styled.span`
   
`

export const CalendarArrowContainer = styled.div`
   display: flex;
   justify-content: flex-end;
`

export const CalendarArrow = styled.img`
   
`