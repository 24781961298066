import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import enTranslations from '@shopify/polaris/locales/en.json';
import {AppProvider} from '@shopify/polaris';
import '@shopify/polaris/build/esm/styles.css';
import { Provider } from 'react-redux';
import store from "./redux/store"

ReactDOM.render(
	<React.StrictMode>
      <Provider store={store}>
         <AppProvider i18n={enTranslations}>
            <App />
         </AppProvider>
      </Provider>
	</React.StrictMode>
	,document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
