import React from 'react'
import { Button } from './Button.Style'

const ButtonComponent = (props) => {
   return (
      <>
         <Button onClick={props.onClick}>{props.text}</Button>
      </>
   )
}

export default ButtonComponent
