import styled from "styled-components";

export const Container = styled.div`
   width: 100%;
   display: flex;
   padding: 20px;
   flex-direction: column;
`

export const IconNameContainer = styled.div`
   width: 90%;
   display: flex;
   justify-content: space-evenly;
   align-items: center;
`

export const IconContainer = styled.div`
   width: 60px;
   height: 60px;
`

export const Icon = styled.img`
   width: 100%;
   height: 100%;
`

export const NameContainer = styled.div`
   &:nth-child(2){
      width: 60%;
   }
`

export const Name = styled.span`
   font-size: 2rem;
   font-weight: bold;
`

export const TextContainer = styled.div`
   margin-top: 1.5rem;
   padding-left: 15px;
`

export const Text = styled.p`
   font-size: 1.6rem;
   
   &:nth-child(1){
     font-weight: bold;
   }
   &:nth-child(2){
     margin: 0.3rem 0;
   }
`

export const Link = styled(Text)`
   cursor: pointer;
   color: var(--link);

   &:hover{
      text-decoration: underline;
   }
`