import styled from "styled-components";

export const OldContainer = styled.div`
   height: 90vh;
   width: 100%;

   display: flex;
   justify-content: center;
   align-items: center;
`

export const OldSubContainer = styled.div`
   height: 90%;
   width: 90%;

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
`

export const OldCreateContainer = styled.div`
   height: 7vh;
   width: 100%;

   display: flex;
   align-items: center;
   justify-content: flex-end;
`

export const OldCreate = styled.div`
   height: 100%;
   cursor: pointer;
   color: var(--green);
   font-size: 2rem;
   border: 3px solid var(--green);
   border-radius: 5px;
   display: flex;
   font-weight: bold;
   justify-content: center;
   align-items: center;
   padding: 0 10px;

   &:hover{
      color: white;
      background-color: var(--green);
   }

   &:nth-child(2){
      margin-left: 2rem;
   }
`

export const OldOrgContainer = styled.div`
   height: 100%;
   width: 100%;
   margin-top: 2rem;
   display: flex;
   justify-content: space-evenly;
   align-items: flex-start;
`

export const OldOrg = styled.div`
   width: 20vw;
   height: 45vh;
   border-radius: 5px;
   background: #FFFFFF;
   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
`

export const OldOrgBg = styled.div`
   width: 100%;
   height: 35%;
`

export const OldOrgBgImg = styled.img`
   width: 100%;
   height: 100%;
   border-radius: 5px;
`

export const AvatarContainer = styled.div`
   transform: translateY(-55%) translateX(30%);
`

export const OldOrgTextContainer = styled.div`

   transform: translateY(-15%);
   display: flex;
   flex-direction: column;

   width: 100%;
   height: 100%;
   padding: 0 15px;
`

export const OldOrgName = styled.span`
   display: flex;
   font-size: 1.7rem;
   font-weight: bold;
   margin-bottom: 10px;
   justify-content: center;
`

export const OldOrgText = styled.span`
   display: flex;
   font-size: 1.25rem;
   line-height: 15px;
   text-align: center;
`

export const OldOrgArrow = styled.div`
   width: 100%;
   height: 5%;
   cursor: pointer;
   display: flex;
   justify-content: flex-end;
   margin-top: 1.5rem;
`

export const OldOrgArrowImg = styled.img`
   height: 100%;
   width: 7%;
`