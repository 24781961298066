const ActionTypes = {
    CREATE_ACCOUNT: "CREATE_ACCOUNT",
    USER_LOGGED_IN: "USER_LOGGED_IN",
    USER_DETAIL_UPDATE: "USER_DETAIL_UPDATE",
    CREATE_ORGANIZATION: "CREATE_ORGANIZATION",
    ORG_DETAIL_UPDATE: "ORG_DETAIL_UPDATE",
    ORGANIZATION_SELECTED: "ORGANIZATION_SELECTED",
    NAVBAR_UPDATE_ACTIVE: "NAVBAR_UPDATE_ACTIVE"
}

export default ActionTypes