import styled from "styled-components";

export const Container = styled.div`
   width: 100%;
   height: 100%;

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
`

export const ImgContainer = styled.div`
   width: 70%;
   height: 60%;
`

export const Img = styled.img`
   width: 100%;
   height: 100%;
`

export const TextContainer = styled.div`
   width: 85%;
   height: 15%;
   margin: 1rem 0;

   display: flex;
   flex-direction: column;
   justify-content: space-between;
`

export const HeadingContainer = styled.div`
   
`

export const Heading = styled.h2`
   font-weight: bold;
   font-size: 1.7rem;
`

export const DescContainer = styled.div`
   margin-top: 0.5rem;
`

export const Desc = styled.span`
   
`

export const ButtonContainer = styled.div`
   width: 90%;
   height: 20%;

   display: flex;
   justify-content: space-evenly;
   align-items: center;
`

export const Button = styled.div`
   color: var(--green);
   border: 2px solid #cacaca;
   padding: 10px;
   font-weight: bold;
   border-radius: 5px;
   cursor: pointer;
   
   &:nth-child(2){
      border: 2px solid var(--green);
      background-color: var(--green);
      color: white;
   }

`