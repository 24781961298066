import styled from "styled-components"

export const Container = styled.div`
   width: 100%;
   height: 100%;

   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
`

export const IconNameContainer = styled.div`
   width: 90%;
   height: 17%;
   display: flex;
   justify-content: space-evenly;
   align-items: center;
`

export const IconContainer = styled.div`
   width: 20%;
   height: 80%;
`

export const ComIcon = styled.img`
   width: 100%;
   height: 100%;
`

export const NameContainer = styled.div`
   width: 70%;
   height: 80%;
   display: flex;
   align-items: center;
`

export const Heading = styled.h1`
   font-size: 2rem;
   font-weight: bold;
`

export const TextContainer = styled.div`
   width: 90%;
   height: 12%;
`

export const Text = styled.span`
   font-size: 1.4rem;
`

export const MemberContainer = styled.div`
   width: 95%;
   height: 45%;
   display: flex;
   justify-content: space-around;
   align-items: center;
   flex-direction: column;
`

export const SelectAllContainer = styled.div`
   width: 90%;
   height: 10%;
   display: flex;
   justify-content: flex-end;
`

export const Select = styled.span`
   color: var(--green);
   font-weight: 600;
`

export const MemberListContainer = styled.div`
   width: 90%;
   height: 85%;
   overflow-y: auto;

   display: flex;
   flex-direction: column;
`

export const InputAvatarNameContainer = styled.div`
   width: 100%;
   height: 45px;
   margin: 5px 0;
   display: flex;
`

export const InputContainer = styled.div`
   height: 100%;
   width: 5%;
   display: flex;
   justify-content: center;
   align-items: center;
`

export const ComInput = styled.input`
   
`

export const AvatarContainer = styled.div`
   height: 100%;
   width: 20%;
   margin-left: 2rem;
   display: flex;
   align-items: center;
`

export const MemNameContainer = styled.div`
   width: 60%;
   display: flex;
   align-items: center;
`

export const Name = styled.span`
   font-size: 1.8rem;
`

export const PostContainer = styled.div`
   width: 90%;
   height: 16%;
   display: flex;
   justify-content: space-evenly;
`

export const PostInputContainer = styled.div`
   width: 65%;
   height: 100%;
   display: flex;
   align-items: center;
`

export const PostButtonContainer = styled.div`
   width: 25%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
`

export const PostButton = styled.div`
   width: 100%;
   text-align: center;
   background-color: var(--green);
   color: white;
   /* border: 2px solid var(--green); */
   padding: 10px;
   font-size: 1.7rem;
   font-weight: bold;
   border-radius: 5px;
   cursor: pointer;
`