import React from 'react'
import FundsComponent from '../../components/Dashboard/Funds/Funds.Component'
import MeetingComponent from '../../components/Dashboard/Meeting/Meeting.Component'
import OrganizationComponent from '../../components/Dashboard/Organization/Organization.Component'
import PollComponent from '../../components/Dashboard/Poll/Poll.Component'
import TaskComponent from '../../components/Dashboard/Tasks/Task.Component'
import { useDispatch,  } from 'react-redux';
import * as Actions from "../../redux/actions/Actions"
import { Container, LeftContainer, RightContainer, SubContainer, OrgLink, FundLink, MeetLink } from './Dashboard.style'

const Dashboard = () => {
	const dispatch = useDispatch();

   const updateActive = (page) => {
      dispatch(Actions.activeNav(page));
   }

	return (
		<>
			<Container>
				<SubContainer>
					<LeftContainer>
						<OrgLink onClick={() => updateActive("organization")}><OrganizationComponent /></OrgLink>
						<FundLink onClick={() => updateActive("fund")}><FundsComponent /></FundLink>
						<MeetLink onClick={() => updateActive("fund")}><MeetingComponent /></MeetLink>
						<PollComponent />
					</LeftContainer>
					<RightContainer>
						<TaskComponent />
					</RightContainer>
				</SubContainer>
			</Container>
		</>
	)
}

export default Dashboard
