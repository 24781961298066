import React from 'react'
import { Button, ButtonContainer, Container, Desc, DescContainer, Heading, HeadingContainer, Img, ImgContainer, TextContainer } from './Transcation.style'
import taskOrgPage from "../../../assets/taskOrgPage.png"

const Transcation = () => {
   return (
      <Container>
         <ImgContainer>
            <Img src={taskOrgPage} />
         </ImgContainer>
         <TextContainer>
            <HeadingContainer>
               <Heading>Tasks Status</Heading>
            </HeadingContainer>
            <DescContainer>
               <Desc>Add expense/income to keep record of all transactions.</Desc>
            </DescContainer>
         </TextContainer>
         <ButtonContainer>
            <Button>Add Income</Button>
            <Button>Add Expense</Button>
         </ButtonContainer>
      </Container>
   )
}

export default Transcation
