import React from 'react'
import * as tag from "./Member.Style"
import { Avatar, Card, ResourceItem, ResourceList } from '@shopify/polaris'

const MemberComponent = ({icon, heading, button, list, resHeigh, widt}) => {
   return (
      <tag.Container>
         <tag.SubContainer widt={widt}>
            <tag.TextIconAddContainer>
               <tag.TextIconContainer>
                  <tag.IconContainer>
                     <tag.Icon src={icon} />
                  </tag.IconContainer>
                  <tag.TextContainer>
                     <tag.Text>{heading}</tag.Text>
                  </tag.TextContainer>
               </tag.TextIconContainer>
               <tag.AddContainer>
                  <tag.Add>{button}</tag.Add>
               </tag.AddContainer>
            </tag.TextIconAddContainer>
            <tag.ResourceListContainer widt={widt} heigh={resHeigh}>
               <Card>
                  <ResourceList
                     resourceName={{singular: 'customer', plural: 'customers'}}
                     items={list}
                     renderItem={(item) => {
                        const media = <Avatar customer size="medium" name={item.name} />;
                        let email = {};
                        if(item.email === undefined)
                           email = {"height":"35px"}
                        return (
                        <ResourceItem
                           id={item.id}
                           media={media}
                           accessibilityLabel={`View details for ${item.name}`}
                        >
                           <tag.ItemInfo style={email}>
                              <tag.NameEmail>
                                 {item.name}<br />
                                 {item.email !== undefined ? item.email : null}
                              </tag.NameEmail>
                              <tag.NumPos>
                                 <tag.Num>{item.mobno}</tag.Num>
                                 <tag.Pos>{item.pos}</tag.Pos>
                              </tag.NumPos>
                           </tag.ItemInfo>
                        </ResourceItem>
                        );
                     }}
                  />
               </Card>
            </tag.ResourceListContainer>
         </tag.SubContainer>
      </tag.Container>
   )
}

export default MemberComponent
