import ActionTypes from "../constants/ActionTypes"

export const createAccount = (user) => {
   return {
      type: ActionTypes.CREATE_ACCOUNT,
      payload: user
   }
}

export const userLoggedIn = (user) => {
   return {
      type: ActionTypes.USER_LOGGED_IN,
      payload: user
   }
}

export const userDetailUpdate = (user) => {
   return {
      type: ActionTypes.USER_DETAIL_UPDATE,
      payload: user
   }
}

export const createOrg = (org) => {
   return {
      type: ActionTypes.CREATE_ORGANIZATION,
      payload: org
   }
}

export const orgDetailUpdate = (org) => {
   return {
      type: ActionTypes.ORG_DETAIL_UPDATE,
      payload: org
   }
}

export const orgSelect = (org) => {
   return {
      type: ActionTypes.ORGANIZATION_SELECTED,
      payload: org
   }
}

export const activeNav = (str) => {
   return {
      type: ActionTypes.NAVBAR_UPDATE_ACTIVE,
      payload: str
   }
}