import ActionTypes from "../constants/ActionTypes";

const initialStateObject = {}
const initialStateArray = []

export const account = (state = initialStateObject, {type, payload}) => {
    switch (type) {
        case ActionTypes.CREATE_ACCOUNT:
            return {...state, ...payload}
        case ActionTypes.USER_LOGGED_IN:
            return {...state, ...payload}
        case ActionTypes.USER_DETAIL_UPDATE:
            return {...payload}
        default:
            return state
    }
}

export const organi = (state = initialStateArray, {type, payload}) => {
    switch (type){
        case ActionTypes.CREATE_ORGANIZATION:
            if(state.length === 0)
                return [{...payload}]
            else
                return [...state,{...payload}]
        case ActionTypes.ORG_DETAIL_UPDATE:
            if(state.length === 0)
                return [...payload]               
            else
                return [...state,{...payload}]
        default: 
            return state
    }
}

export const orgSelection = (state = initialStateObject, {type, payload}) => {
    switch(type) {
        case ActionTypes.ORGANIZATION_SELECTED:
            return {...payload}
        default:
            return state
    }
}

export const activeNav = (state = "dashboard", {type, payload}) => {
    switch(type){
        case ActionTypes.NAVBAR_UPDATE_ACTIVE:
            return payload
        default:
            return state
    }
}