import styled from "styled-components";

export const TaskBoxContainer = styled.div`
   width: 100%;
   height: 100%;
   background-color: white;
   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
   border-radius: 5px;

   display: flex;
   align-items: center;
   flex-direction: column;
`

export const TaskIconNameOption = styled.div`
   width: 100%;
   height: 20%;
   display: flex;
   justify-content: space-evenly;
   align-items: center;
`

export const TaskIconContainer = styled.div`
   width: 10%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
`

export const TaskIcon = styled.img`
   height: 70%;
`

export const TaskNameOptionContainer = styled.div`
   width: 40%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   height: 100%;
`

export const TaskEditingButtonContainer = styled.div`
   height: 100%;
   width: 35%;

   display: flex;
   justify-content: space-evenly;
   align-items: center;
`

export const TaskEditingButton = styled.div`
   width: 30%;
   height: 40%;

   display: flex;
   align-items: flex-end;
   justify-content: center;
   padding-bottom: 1rem;

`

export const TaskEditingButtonGrp = styled.div`
   width: 75%;

   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
`

export const TaskEditingButtonIcon = styled.img`
   width: 17px;
   height: 17px;

   ${TaskEditingButton}:nth-child(1) ${TaskEditingButtonGrp} &{
      width: 28px;
      height: 28px;
   }
`

export const TaskEditingButtonText = styled.span`
   font-size: 1.7rem;
   padding: 5px;
   font-weight: bold;
   color: var(--green);
`

export const TaskNameContainer = styled.div`
   margin-bottom: 1.5rem;
`

export const TaskName = styled.h1`
   font-weight: bold;
   font-size: 2rem;
`

export const TaskOptionContainer = styled.div`
   /* margin-top: 1rem; */
`

export const TaskDescContainer = styled.div`
   width: 88%;
   height: 15%;
   max-height: 25%;
   display: flex;
   justify-content: space-between;
`

export const TaskDescAboutContainer = styled.div`
   width: 62%;
   height: 100%;
   overflow-y: auto;
`

export const TaskDescEmEcContainer = styled.div`
   width: 36%;
   height: 100%;
   overflow-y: auto;
`

export const TaskDescHeadingContainer = styled.div`
   height: 20%;
   display: flex;
   align-items: center;
`

export const TaskDescDescContainer = styled.div`
   display: flex;
   flex-direction: column;
   padding-top: 10px;
`

export const TaskDescHeading = styled.h2`
   font-weight: bold;
   font-size: 2rem;
   color: var(--green);
`

export const TaskDescAbout = styled.span`
   
`

export const TaskDescEmEcHeading = styled.span`
   font-weight: bold;
`

export const TaskContainer = styled.div`
   height: 32%;
   width: 88%;

   &:nth-child(3){
      margin-top: 1.5rem;
   }
`

export const TaskHeadingContainer = styled.div`
   margin-bottom: 1rem;
`

export const TaskHeading = styled.h1`
   font-size: 2rem;
   font-weight: bold;
   color: var(--green);
`

export const TaskListTable = styled.div`
   height: 180px;
   width: 100%;
   /* overflow-y: auto; */
   padding-right: 25px;
   overflow-y: auto;
   display: flex;

   flex-direction: column;
`

export const TaskListTableRow = styled.div`
   display: flex;
   /* background-color: red; */
   height: 30px;
   width: 96%;
   /* margin-bottom: 10px; */
   ${TaskListTable} &{
      height: 45px;
      width: 100%;
   }
`

export const TaskListTableCell = styled.div`
   height: 50px;
   line-height: 20px;
   
   ${TaskListTable} ${TaskListTableRow} &:nth-child(1){
      width: 40%;
   }
   ${TaskListTable} ${TaskListTableRow} &:nth-child(2){
      width: 15%;
      display: flex;
      justify-content: center;
   }
   ${TaskListTable} ${TaskListTableRow} &:nth-child(3){
      display: flex;
      justify-content: center;
      width: 20%;
   }
   ${TaskListTable} ${TaskListTableRow} &:nth-child(4){
      display: flex;
      justify-content: center;
      width: 15%;
   }
   ${TaskListTable} ${TaskListTableRow} &:nth-child(5){
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
   }
`

export const TaskListTableHead = styled.div`
   
   
   &:nth-child(1){
      width: 40%;
   }
   &:nth-child(2){
      width: 15%;
      display: flex;
      justify-content: center;
   }
   &:nth-child(3){
      display: flex;
      justify-content: center;
      width: 20%;
   }
   &:nth-child(4){
      display: flex;
      justify-content: center;
      width: 15%;
   }
   &:nth-child(5){
      width: 10%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
   }
`

export const TaskStatusInput = styled.input`
   &:checked::before{
      background-color: green !important;
   }
`