import styled from "styled-components"

export const FeatureContainer = styled.div`
   width: 100%;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
`

export const FeatureSubContainer = styled.div`
   width: 60%;
   height: 90%;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: center;
`

export const FeatureHeadingContainer = styled.div`
   width: 100%;
   height: 10%;
   display: flex;
   justify-content: center;
   align-items: center;
`

export const FeatureHeading = styled.h3`
   color: var(--green);
   font-weight: 600;
   font-size: 40px;
`

export const FeatureImageContainer = styled.div`
   width: 100%;
   height: 65%;
   display: flex;
   justify-content: center;
   align-items: center;
`

export const FeatureImage = styled.img`
   width: 100%;
   height: 95%;
`

export const FeatureTextContainer = styled.div`
   width: 70%;
   height: 25%;
   display: flex;
   justify-content: center;
   align-items: center;
`

export const FeatureText = styled.span`
   text-align: center;
`