import React, { useCallback, useState } from 'react'
import { useSelector } from "react-redux"
import * as tag from "./Header.style"
import EveManLogo from "../../../assets/EveManLogo.png"
import { Avatar, Select } from '@shopify/polaris'

const HeaderComponent = (props) => {
   const { users } = useSelector(state => state)
   const userInitials = users.name.split(" ")[0][0] + users.name.split(" ")[1][0]

   const [selected, setSelected] = useState('Divine Circle');
   const handleSelectChange = useCallback((value) => setSelected(value), [])
   const options = [
      {label: "Divine Circle", value: "Divine Circle"},
      {label: "GTBIT Finishing School", value: "GTBIT Finishing School"},
      {label: "Google Developer Student Clubs", value: "Google Developer Student Clubs"}
   ]

   return (
      <tag.HeaderContainer>
         <tag.HeaderNameIconContainer>
            <tag.HeaderIcon src={EveManLogo} />
            <tag.HeaderWebName>EveMan</tag.HeaderWebName>
         </tag.HeaderNameIconContainer>
         {
            props.NoSelect ?
            "" : 
            <tag.SelectContainer>
               <Select options={options} onChange={handleSelectChange} value={selected} />
            </tag.SelectContainer>
         }
         <tag.HeaderUserName>
            <Avatar customer={false} initials={userInitials} name={users.name} />
            <tag.HeaderName>{users.name}</tag.HeaderName>
         </tag.HeaderUserName>
      </tag.HeaderContainer>
   )
}

export default HeaderComponent
