import React from 'react'
import fundOrgPage from "../../../assets/fundOrgPage.png"
import { IconContainer, TextContainer, Text, Icon, Container, IconNameContainer, Name, NameContainer, Link } from "./LatestMeet.style"

const LatestMeet = () => {
   return (
      <Container>
         <IconNameContainer>
            <IconContainer>
               <Icon src={fundOrgPage} />
            </IconContainer>
            <NameContainer>
               <Name>Meetings</Name>
            </NameContainer>
            <NameContainer>
            </NameContainer>
         </IconNameContainer>
         <TextContainer>
            <Text>Team meeting</Text>
            <Text>Friday, Jan 14, 11:00am - 12:30pm</Text>
            <Link>meet.google.com/mbg-cuaj-cmt</Link>
         </TextContainer>
      </Container>
   )
}

export default LatestMeet
