import styled from "styled-components";

export const Container = styled.div`
   width: 100%;
   height: 120vh;

   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #eee;
`

export const SubContainer = styled.div`
   width: 90%;
   height: 97%;

   display: flex;
   justify-content: space-between;
`

export const LeftContainer = styled.div`
   width: 28%;
   height: 100%;

   display: flex;
   justify-content: space-between;
   flex-direction: column;
`

export const RightContainer = styled.div`
   width: 70%;
   height: 100%;
   background-color: white;
   display: flex;
   justify-content: space-between;
   /* flex-direction: column; */
`

export const OrgLink = styled.a`
   width: 100%;
   height: 13%;
   text-decoration: none;
   cursor: pointer;
`

export const FundLink = styled(OrgLink)`
   height: 13%;
`

export const MeetLink = styled(OrgLink)`
   height: 28%;
`