import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router";
import Request from "../../Common/Requests/request";
import * as Actions from "../../../redux/actions/Actions"
import * as Form from "../../Common/Form.styles";
import FormInput from "../../Common/FormInput";
import At from "../../../assets/common/at.svg"
import Eye from "../../../assets/common/eye.svg"
import EyeSlash from "../../../assets/common/eye-slash.svg"
import User from "../../../assets/common/user.svg"
import Mobile from "../../../assets/common/mobile.svg"
import port from "../../../port"

const SignUpForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [user, setUser] = useState({
		name: "",
		email: "",
		password: "",
		mobNo: "",
	});

	const handleChange = async (event) => {
		const { name, value } = event.target;
		setUser((prevValue) => ({ ...prevValue, [name]: value }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		let header = {
			"Content-Type": "application/json"
		}
		Request.post("http://localhost:" + port + "/login/SignUp", user, header)
			.then((res) => {
				if (res.message !== "Email account exist") {
					const userData = {
						id: res.user.id,
						name: res.user.name,
						email: res.user.email,
						mobNo: res.user.mobNo,
						organizations: res.user.organizations
					}
					dispatch(Actions.createAccount(userData));
					navigate('/home')
				}
			})
			.catch(err => console.log(err))
	};

	return (
		<Form.FormContainer action="" method="" onSubmit={handleSubmit}>
			<Form.FormHeading>Create an account</Form.FormHeading>
			<Form.FormText>
				Already have an account? <Form.FormLink to="/">Sign In</Form.FormLink>
			</Form.FormText>
			<FormInput
				icon={User}
				value={user.name}
				handleChange={handleChange}
				name="name"
				type="text"
				placeholder="Name"
			// required
			/>
			<FormInput
				icon={At}
				type="email"
				name="email"
				placeholder="Email"
				// required
				value={user.email}
				handleChange={handleChange}
			/>

			<FormInput
				icon={Mobile}
				value={user.mobNo}
				handleChange={handleChange}
				name="mobNo"
				type="number"
				placeholder="Mobile Number"
			// required
			/>
			<FormInput
				icon={Eye}
				iconHide={EyeSlash}
				type="password"
				placeholder="Password"
				value={user.password}
				name="password"
				handleChange={handleChange}
			// required
			/>

			<Form.FormLink to="/" style={{ alignSelf: "flex-end" }}>
				Forget Password?
			</Form.FormLink>
			<Form.PrimaryButton type="submit">Create Account</Form.PrimaryButton>
		</Form.FormContainer>
	);
};

export default SignUpForm;
