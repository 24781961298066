import styled from "styled-components";

export const AssetContainer = styled.div`
   width: 100%;
   height: 100%;
   background-color: white;
   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
   border-radius: 5px;

   display: flex;
   justify-content: center;
   align-items: center;
`

export const AssetAvatarContainer = styled.div`
   width: 30%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
`

export const AssetAvatar = styled.img`
   
`

export const AssetTextContainer = styled.div`
   width: 70%;
   height: 100%;
   display: flex;
   padding: 1rem 0;
   flex-direction: column;
   justify-content: space-evenly;
`

export const AssetHeadingContainer = styled.div`
   width: 100%;
`

export const AssetHeading = styled.h2`
   font-size: 2rem;
   font-weight: bold;
`

export const AssetDescContainer = styled.div`
   margin: 0.7rem 0;
`

export const AssetDesc = styled.span`
   
`

export const AssetArrowContainer = styled.div`
`

export const AssetArrow = styled.div`
   width: 10rem;
   height: 3rem;
   display: flex;
   justify-content: center;
   align-items: center;
   font-weight: 500;
   color: white;
   border-radius: 5px;
   background-color: var(--green);
`