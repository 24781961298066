import React, { useState } from 'react'
import * as tag from "./Attendees.style"
import { Avatar, Card, Pagination, ResourceItem, ResourceList } from '@shopify/polaris'

const AttendeesComponent = ({icon, heading, attlist, resHeigh, widt}) => {
   const [count, setCount] = useState(0);
   return (
      <tag.Container>
         <tag.SubContainer widt={widt}>
            <tag.TextIconAddContainer>
               <tag.TextIconContainer>
                  <tag.IconContainer>
                     <tag.Icon src={icon} />
                  </tag.IconContainer>
                  <tag.TextContainer>
                     <tag.Text>{heading}</tag.Text>
                  </tag.TextContainer>
               </tag.TextIconContainer>
               <tag.AddContainer>
                  <Pagination
                     label={attlist[count].date}
                     hasPrevious
                     previousKeys={[74]}
                     previousTooltip="previous date"
                     onPrevious={() => {
                        let counter;
                        if(count === 0)
                           counter = attlist.length - 1;
                        else
                           counter = count - 1;
                        setCount(counter);
                     }}
                     hasNext
                     nextKeys={[75]}
                     nextTooltip="next date"
                     onNext={() => {
                        let counter;
                        if(count === attlist.length - 1)
                           counter = 0;
                        else
                           counter = count + 1;
                        setCount(counter);
                     }}
                  />
               </tag.AddContainer>
            </tag.TextIconAddContainer>
            <tag.ResourceListContainer widt={widt} heigh={resHeigh}>
               <Card>
                  <ResourceList
                     resourceName={{singular: 'customer', plural: 'customers'}}
                     items={attlist[count].attlist}
                     renderItem={(item) => {
                        const media = <Avatar customer size="medium" name={item.name} />;
                        let email = {};
                        if(item.email === undefined)
                           email = {"height":"35px"}
                        return (
                        <ResourceItem
                           id={item.id}
                           media={media}
                           accessibilityLabel={`View details for ${item.name}`}
                        >
                           <tag.ItemInfo style={email}>
                              <tag.NameEmail>
                                 {item.name}<br />
                                 {item.email !== undefined ? item.email : null}
                              </tag.NameEmail>
                              <tag.NumPos>
                                 <tag.Num>{item.mobno}</tag.Num>
                                 <tag.Pos>{item.pos}</tag.Pos>
                              </tag.NumPos>
                           </tag.ItemInfo>
                        </ResourceItem>
                        );
                     }}
                  />
               </Card>
            </tag.ResourceListContainer>
         </tag.SubContainer>
      </tag.Container>
   )
}

export default AttendeesComponent
