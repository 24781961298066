import React from "react";
import { Avatar } from "@shopify/polaris";
import orgBg from "../../../assets/orgBg.png";
import * as tag from "./About.Style";
import facebook from "../../../assets/facebook.svg";
import instagram from "../../../assets/instagram.svg";
import linkedin from "../../../assets/linkedin.svg";
import pic from "../../../assets/dc.png";

const AboutComponent = () => {
  return (
    <tag.AboutContainer>
      <tag.AboutOrgBg>
        <tag.AboutOrgBgImg src={orgBg} />
      </tag.AboutOrgBg>
      <tag.AboutOrgInfo>
        <tag.AvatarContainer>
          <Avatar size="extraSmall" customer={true} source={pic} />
        </tag.AvatarContainer>
        <tag.AboutOrgInfoLeftContainer>
          <tag.AboutOrgName>Divine Circle</tag.AboutOrgName>
          <tag.AboutOrgShortDesc>
            divine_circle@gmail.com{" "}
          </tag.AboutOrgShortDesc>
        </tag.AboutOrgInfoLeftContainer>
        <tag.AboutOrgInfoRightContainer>
          <tag.AboutOrgAboutHeadingContainer>
            <tag.AboutOrgAboutHeading>About Us</tag.AboutOrgAboutHeading>
          </tag.AboutOrgAboutHeadingContainer>
          <tag.AboutOrgAboutDescContainer>
            <tag.AboutOrgAboutDesc>
              Divine Circle is the official Divinity Society of GTBIT. We
              enthusiastically conduct both Spiritual and Non-spiritual events.
              We believe in working on the development of ourselves as an
              individual and at the team level as well. 🫂 Divine Circle has 5
              major categories:- Gatka, Kirtan, Literary, Event Management,
              Creative.{" "}
            </tag.AboutOrgAboutDesc>
          </tag.AboutOrgAboutDescContainer>
          <tag.AboutOrgSocialContainer>
            <tag.AboutOrgSocialItem>
              <tag.AboutOrgSocialIcon src={facebook} />
              <tag.AboutOrgSocialUserName>dcgtbit</tag.AboutOrgSocialUserName>
            </tag.AboutOrgSocialItem>
            <tag.AboutOrgSocialItem>
              <tag.AboutOrgSocialIcon src={instagram} />
              <tag.AboutOrgSocialUserName>dcgtbit</tag.AboutOrgSocialUserName>
            </tag.AboutOrgSocialItem>
            <tag.AboutOrgSocialItem>
              <tag.AboutOrgSocialIcon src={linkedin} />
              <tag.AboutOrgSocialUserName>dcgtbit</tag.AboutOrgSocialUserName>
            </tag.AboutOrgSocialItem>
          </tag.AboutOrgSocialContainer>
        </tag.AboutOrgInfoRightContainer>
      </tag.AboutOrgInfo>
    </tag.AboutContainer>
  );
};

export default AboutComponent;

/*
         <tag.AboutSubContainer style={{position: "relative"}}>
            <tag.AboutOrgBg>
               <tag.AboutOrgBgImg src={orgBg} />
            </tag.AboutOrgBg>
            <tag.AvatarContainer>
               <Avatar size="large" customer={true} />
            </tag.AvatarContainer>
         </tag.AboutSubContainer>
*/
