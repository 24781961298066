import React from "react";
import * as tag from "./Poll.Style";
import PollOrgPage from "../../../assets/pollOrgPage.png";
import PollArrow from "../../../assets/rightArrow.png";

const PollComponent = () => {
  const Poll = {
    ques: "Which colour of the jackets u wish to finalize?",
  };
  const option = ["White", "Black", "Grey"];

  const Options = option.map((e, key) => {
    return <tag.PollOption key={key++}>{e}</tag.PollOption>;
  });

  return (
    <tag.PollContainer>
      <tag.PollTopicNameIcon>
        <tag.PollAvatarContainer>
          <tag.PollAvatar src={PollOrgPage} />
        </tag.PollAvatarContainer>
        <tag.PollTextContainer>
          <tag.PollHeadingContainer>
            <tag.PollHeading>Organisation Poll</tag.PollHeading>
          </tag.PollHeadingContainer>
          <tag.PollCreateContainer>
            <tag.PollCreate>+ Conduct a Poll</tag.PollCreate>
          </tag.PollCreateContainer>
        </tag.PollTextContainer>
      </tag.PollTopicNameIcon>
      <tag.PollDescContainer>
        <tag.PollDesc>{Poll.ques}</tag.PollDesc>
      </tag.PollDescContainer>
      <tag.PollOptionsContainer>{Options}</tag.PollOptionsContainer>
      <tag.PollArrowContainer>
        <tag.PollArrow src={PollArrow} />
      </tag.PollArrowContainer>
    </tag.PollContainer>
  );
};

export default PollComponent;
