import React, { useState } from 'react'
import * as tag from "./OldHome.style"
import orgBg from "../../../assets/orgBg.png"
import rightArrow from "../../../assets/rightArrow.png"
import { Avatar } from '@shopify/polaris'
import { useDispatch, useSelector } from 'react-redux';
import StyledModal from "../../Common/CustomModal/CustomModal"
import CreateOrgModal from '../../ModalComponents/CreateOrganizationModal/CreateOrganizationModal'
import JoinOrgModal from '../../ModalComponents/JoinOrganizationModal/JoinOrganizationModal'
import { useNavigate } from "react-router";
import * as Actions from "../../../redux/actions/Actions"

const OldHomeComponent = () => {
   const [modalShow, setModalShow] = React.useState(false);

   const dispatch = useDispatch();
   const navigate = useNavigate();
   const [componentForm, setComponentForm] = useState({
      content: JoinOrgModal,
   });

   const handleClick = (e, Component) => {
      componentForm.content = Component;
      setComponentForm(componentForm);
      setModalShow(true);
   };

   const org = useSelector((state) => state.orgs);

   const selectOrg = (id) => {
      for(let i = 0; i < org.length; i++){
         if(org[i].id === id){
            dispatch(Actions.orgSelect(org[i]))
            navigate("/dashboard")
         }
      }
   }


   const organizations = org.map((e, count) => {
      return (
         <tag.OldOrg key={count++}>
            <div style={{ position: "relative" }}>
               <tag.OldOrgBg>
                  <tag.OldOrgBgImg src={orgBg} />
               </tag.OldOrgBg>
               <tag.AvatarContainer>
                  <Avatar size="extraSmall" customer={true} source={e.logo} />
               </tag.AvatarContainer>
            </div>
            <tag.OldOrgTextContainer>
               <tag.OldOrgName>{e.name}</tag.OldOrgName>
               <tag.OldOrgText>{e.about.length > 180 ? e.about.slice(0, 177) + "..." : e.about}</tag.OldOrgText>
               <tag.OldOrgArrow onClick={() => selectOrg(e.id)} >
                  <tag.OldOrgArrowImg src={rightArrow} />
               </tag.OldOrgArrow>
            </tag.OldOrgTextContainer>
         </tag.OldOrg>
      );
   })

   /*
   const urlLists = urls.map((urlVal) => {
      return <SectionImage key={urlVal.id} url={urlVal.url} imgNum={urlVal.id} />;
   });
   */

   return (
      <tag.OldContainer>
         <tag.OldSubContainer>
            <tag.OldCreateContainer>
               <tag.OldCreate onClick={(e) => handleClick(e, CreateOrgModal)}>Create Organization</tag.OldCreate>
               <tag.OldCreate onClick={(e) => handleClick(e, JoinOrgModal)}>Join Organization</tag.OldCreate>
            </tag.OldCreateContainer>
            <tag.OldOrgContainer>
               {organizations}
            </tag.OldOrgContainer>
         </tag.OldSubContainer>
         <StyledModal showModal={modalShow} setShowModal={setModalShow} Component={componentForm.content} />
      </tag.OldContainer>
   )
}

export default OldHomeComponent
