import styled from "styled-components";

export const MeetContainer = styled.div`
   width: 100%;
   height: 100%;
   background-color: white;
   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
   border-radius: 5px;

   display: flex;
   justify-content: center;
   align-items: center;
`

export const MeetAvatarContainer = styled.div`
   width: 30%;
   height: 100%;
   display: flex;
   padding: 2rem 1rem;
   justify-content: center;
   align-items: flex-start;
`

export const MeetAvatar = styled.img`
   
`

export const MeetTextContainer = styled.div`
   width: 70%;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
`

export const MeetHeadingContainer = styled.div`
   width: 100%;
   &:nth-child(2){
      height: 20%;
   }
`

export const MeetHeading = styled.h2`
   font-size: 2rem;
   font-weight: bold;
`

export const MeetDescContainer = styled.div`
   margin-top: 0.5rem;
   display: flex;
   flex-direction: column;
   height: 25%;
`

export const MeetDesc = styled.span`
   
`

export const MeetTimeStamp = styled.span`
   
`

export const MeetLink = styled.span`
   color: var(--link);
   cursor: pointer;
   
   &:hover{
      text-decoration: underline;
   }
`

export const MeetArrowContainer = styled.div`
   display: flex;
   justify-content: flex-end;
   padding-right: 3rem;
`

export const MeetArrow = styled.img`
   
`