import React, { useState } from 'react'
import * as tag from "./NewHome.style"
import * as tag2 from "../../Landing/HeroBoxComponent/HeroBox.Style"
import Eve from "../../../assets/EmptyHome.png"
import ButtonComponent from "../../Common/EmptyButtonComponent/Button.Component"
import StyledModal from "../../Common/CustomModal/CustomModal"
import CreateOrgModal from '../../ModalComponents/CreateOrganizationModal/CreateOrganizationModal'
import JoinOrgModal from '../../ModalComponents/JoinOrganizationModal/JoinOrganizationModal'
 
const NewHomeComponent = () => {
   const [modalShow, setModalShow] = React.useState(false);
   
   const [componentForm, setComponentForm] = useState({
      content: JoinOrgModal,
   });

   const handleClick = (e, Component) => {
      componentForm.content = Component;
      setComponentForm(componentForm);
      setModalShow(true);
   };

   return (
      <tag.NewContainer>
         <tag.NewSubContainer>
            <tag.NewLeftContainer>
               <tag.NewLeftImage src={Eve} />
            </tag.NewLeftContainer>
            <tag.NewRightContainer>
               <tag2.heroHeadingContainer>
                  <tag.NewRightHeading>Empty Homepage???</tag.NewRightHeading>
               </tag2.heroHeadingContainer>
               <tag2.heroContentContainer>
                  <tag2.heroContent>
                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lobortis risus nec metus luctus lacinia in nec sapien. Quisque nec interdum neque. Ut non massa id odio auctor 
                  </tag2.heroContent>
               </tag2.heroContentContainer>
               <tag2.heroButtonContainer>
                  <ButtonComponent text="Join Organisation" onClick={(e) => handleClick(e, JoinOrgModal)} />
                  <ButtonComponent text="Create Organisation" onClick={(e) => handleClick(e, CreateOrgModal)} />
               </tag2.heroButtonContainer>
            </tag.NewRightContainer>
         </tag.NewSubContainer>
         <StyledModal showModal={modalShow} setShowModal={setModalShow} Component={componentForm.content} />
      </tag.NewContainer>
   )
}

export default NewHomeComponent
