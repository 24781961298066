import React from "react";
import * as tag from "./CustomModal.styles";
import CancelImg from "../../../assets/cancel.png"

const StyledModal = ({ showModal, setShowModal, Component }) => {
  return (
    <tag.ModalOverlay style={{ display: showModal ? "flex" : "none" }}>
      <tag.ModalContainer>
        <tag.ModalClose
          draggable={false}
          onClick={() => setShowModal(!showModal)}
          src={CancelImg}
        />
        <tag.ModalBody>{<Component />}</tag.ModalBody>
      </tag.ModalContainer>
    </tag.ModalOverlay>
  )
}

export default StyledModal
