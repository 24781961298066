import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as Form from "../../Common/Form.styles";
import FormInput from "../../Common/FormInput";
import At from "../../../assets/common/at.svg"
import Eye from "../../../assets/common/eye.svg"
import EyeSlash from "../../../assets/common/eye-slash.svg"
import { useNavigate } from "react-router";
import * as Actions from "../../../redux/actions/Actions"
import Request from "../../Common/Requests/request";
import port from "../../../port"

const SignInForm = () => {
  const userData = useSelector(state => state.users);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser((prevValue) => ({ ...prevValue, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Request.post("http://localhost:" + port + "/login/SinUser", user)
      .then((res) => {
        dispatch(Actions.userLoggedIn(res));
        if (res.organizations.length !== 0) {
          let Ids = [];
          for (let i = 0; i < res.organizations.length; i++) {
            Ids[i] = res.organizations[i].id;
          }
          let postFormData = {
            Ids
          }
          Request.post("http://localhost:" + port + "/org/getdetails", postFormData)
            .then((res) => {
              dispatch(Actions.orgDetailUpdate(res));
            })
            .catch(err => console.log(err))
        }
      })
      .then(() => {
        navigate('/home')
      })
      .catch(err => console.log(err))
  };

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      navigate("/home")
    }
  }, [userData, navigate])

  return (
    <Form.FormContainer action="" method="" onSubmit={handleSubmit}>
      <Form.FormHeading>Sign In</Form.FormHeading>
      <Form.FormText>
        New User? <Form.FormLink to="/">Create an Account</Form.FormLink>
      </Form.FormText>
      <FormInput
        icon={At}
        type="email"
        name="email"
        value={user.email}
        placeholder="Email"
        handleChange={handleChange}
      // required
      />
      <FormInput
        icon={Eye}
        iconHide={EyeSlash}
        type="password"
        placeholder="Password"
        value={user.password}
        name="password"
        handleChange={handleChange}
      // required
      />

      <Form.FormLink to="/" style={{ alignSelf: "flex-end" }}>
        Forget Password?
      </Form.FormLink>
      <Form.PrimaryButton type="submit">Sign In</Form.PrimaryButton>
    </Form.FormContainer>
  );
};

export default SignInForm;
