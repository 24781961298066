import styled from "styled-components";

export const NavContainer = styled.div`
   width: 100%;
   height: 7vh;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #eee;
`

export const NavSubContainer = styled.div`
   height: 100%;
   width: 85%;
   padding: 10px 0;
   display: flex;
   justify-content: center;
   align-items: center;
`

export const NavItemContainer = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
`

export const NavItemText = styled.span`
   padding: 10px 20px;
   margin: 5px 15px;
   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
   border-radius: 10px;
   background-color: white;

   &:hover{
      background-color: var(--green);
      color: white;
   }
`

export const NavSelectedItemContainer = styled(NavItemContainer)`
   color: white;
   border-radius: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   
   & ${NavItemText}{
      background-color: var(--green);
      font-weight: bold;
      color: white !important;
   }
`

export const Anchor = styled.a`
   text-decoration: none;
   color: black;
`