import styled from "styled-components";

export const FormContainer = styled.form`
  display: flex;
  padding: 15px 40px;
  flex-direction: column;
  font-family: "Mulish", sans-serif;
  ${(props) => props.widt ? props.widt : null}
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  left: 10px;
  top: 50%;
  opacity: 0.5;
  transform: translateY(${(props) => props.up ? props.up : "-10%"});
  margin: auto;
`;

export const Input = styled.input`
  padding: 10px 20px 10px 40px;
  ${(props) => props.margin === "no" ? null: "margin-top: 15px;"}
  font-size: 16px;
  width: ${(props) => props.widt ? props.widt : "500px"};
  font-family: "Mulish", sans-serif;

  border: 1px solid #cacaca;
  border-radius: 5px;
  outline: none;
`;

export const FormHeading = styled.h2`
  margin: 10px 0;
  font-size: 2.5rem;
  font-weight: bold;
`;
export const FormText = styled.p`
  margin-bottom: 8px;
`;
export const FormLink = styled.a`
  color: var(--green);
  text-decoration: none;
  margin: 5px;
  cursor: pointer;
`;

export const FormInputContainer = styled.div`
  position: relative;
`;

export const FormShowPassword = styled.img`
  width: 22px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-15%);
  margin: auto;
  cursor: pointer;
`;

export const PrimaryButton = styled.button`
  padding: 1rem 1.5rem;
  align-self: center;
  font-size: 1.5rem;
  background: var(--green);
  font-weight: normal;
  cursor: pointer;
  color: white;
  border: none;
  border-radius: 2px;
  margin: 0.5rem 0;
  font-weight: 500;
`;