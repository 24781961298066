import React, { useState } from "react";
import * as tag from "./HeroBox.Style";
import HomeBg from "../../../assets/HomeBg.png";
import ButtonComponent from "../../Common/EmptyButtonComponent/Button.Component";
import StyledModal from "../../Common/CustomModal/CustomModal";
import Signup from "../../ModalComponents/CreateAccountModal/SignUpForm";
import Signin from "../../ModalComponents/SignInAccountModal/SigninForm";

const HeroBoxComponent = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const [componentForm, setComponentForm] = useState({
    content: Signup,
  });

  const handleClick = (e, Component) => {
    componentForm.content = Component;
    setComponentForm(componentForm);
    setModalShow(true);
  };

  return (
    <>
      <tag.heroContainer bgImage={HomeBg}>
        <tag.heroTextContainer>
          <tag.heroSubContainer>
            <tag.heroHeadingContainer>
              <tag.heroHeading>EveMan</tag.heroHeading>
            </tag.heroHeadingContainer>
            <tag.heroContentContainer>
              <tag.heroContent>
                An event management application to organise data and assign
                tasks. Through this web application one can also manage
                finances, post information about team meetings and conduct polls
                and maintains record of past event related data in chunkable
                format.
              </tag.heroContent>
            </tag.heroContentContainer>
            <tag.heroButtonContainer>
              <ButtonComponent
                text="Create an Account"
                onClick={(e) => handleClick(e, Signup)}
              />
              <ButtonComponent
                text="SignIn to your Account"
                onClick={(e) => handleClick(e, Signin)}
              />
            </tag.heroButtonContainer>
          </tag.heroSubContainer>
        </tag.heroTextContainer>
        <StyledModal
          showModal={modalShow}
          setShowModal={setModalShow}
          Component={componentForm.content}
        />
      </tag.heroContainer>
    </>
  );
};

export default HeroBoxComponent;
