import { DatePicker } from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import * as tag from "./Calendar.Style";
import calendarArrow from "../../../assets/rightArrow.png";
import calendarOrgPage from "../../../assets/calendarOrgPage.png";

const CalendarComponent = () => {
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );

  return (
    <tag.CalendarContainer>
      <tag.CalendarAvatarTextContainer>
        <tag.CalendarAvatarContainer>
          <tag.CalendarAvatar src={calendarOrgPage} />
        </tag.CalendarAvatarContainer>
        <tag.CalendarTextContainer heigh="100%" wide="70%">
          <tag.CalendarHeadingContainer>
            <tag.CalendarHeading>Event Calender</tag.CalendarHeading>
          </tag.CalendarHeadingContainer>
        </tag.CalendarTextContainer>
      </tag.CalendarAvatarTextContainer>
      <tag.CalendarPickerContainer>
        <DatePicker
          month={month}
          year={year}
          onChange={setSelectedDates}
          onMonthChange={handleMonthChange}
          selected={selectedDates}
        />
      </tag.CalendarPickerContainer>
      <tag.CalendarTextContainer heigh="30%" wide="80%">
        <tag.CalendarHeadingContainer>
          <tag.CalendarHeading>Kirtan Samagam</tag.CalendarHeading>
        </tag.CalendarHeadingContainer>
        <tag.CalendarDescContainer>
          <tag.CalendarDesc>
            To commemorate the SHAHEEDI PURAB of Chaar Sahibzaade and Mata Gujri
            Ji.
          </tag.CalendarDesc>
        </tag.CalendarDescContainer>
        <tag.CalendarArrowContainer>
          <tag.CalendarArrow src={calendarArrow} />
        </tag.CalendarArrowContainer>
      </tag.CalendarTextContainer>
    </tag.CalendarContainer>
  );
};

export default CalendarComponent;
