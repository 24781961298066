import React from "react";
import LatestMeet from "../../components/Meet/LatestMeet/LatestMeet.component";
import MeetAttendance from "../../components/Meet/MeetAttendance/MeetAttendance.component";
import ScheduleMeet from "../../components/Meet/ScheduleMeet/ScheduleMeet.component";
import meetAttPage from "../../assets/meetAttPage.png";
import AttendeesComponent from "../../components/Meet/Attendees/Attendees.component";
import {
  MeetContainer,
  MeetSubContainer,
  MeetAssetContainer,
  MeetAssetEventContainer,
  MeetEventContainer,
  MeetMemberContainer,
} from "./Meet.style";

const Meet = () => {
  const list = [
    {
      id: 0,
      date: "01/01/2022",
      attlist: [
        {
          name: "Kushdeep Singh",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Core Member",
        },
        {
          name: "Jasveen Kaur",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Core Member",
        },
        {
          name: "Amritsinchan Kaur",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Core Member",
        },
        {
          name: "Jasmeen Kaur",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Jaskeerat Singh",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Divjot Kaur",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Harleen Kaur",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Volunteer",
        },
        {
          name: "Jasjeet Singh",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Volunteer",
        },
        {
          name: "Arshpreet Kaur",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Ashmeet Singh",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Balpreet Singh",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Harman Kaur",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Volunteer",
        },
        {
          name: "Kapil",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Volunteer",
        },
        {
          name: "Pavneet Kaur",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Volunteer",
        },
      ],
    },
    {
      id: 1,
      date: "02/01/2022",
      attlist: [
        {
          name: "Jasveen Kaur",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Core Member",
        },
        {
          name: "Kushdeep Singh",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Core Member",
        },
        {
          name: "Amritsinchan Kaur",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Core Member",
        },
        {
          name: "Jasmeen Kaur",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Jaskeerat Singh",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Divjot Kaur",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Harleen Kaur",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Volunteer",
        },
        {
          name: "Jasjeet Singh",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Volunteer",
        },
        {
          name: "Arshpreet Kaur",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Ashmeet Singh",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Balpreet Singh",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Harman Kaur",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Volunteer",
        },
        {
          name: "Kapil",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Volunteer",
        },
        {
          name: "Pavneet Kaur",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Volunteer",
        },
      ],
    },
    {
      id: 2,
      date: "03/01/2021",
      attlist: [
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Core Member",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Core Member",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Core Member",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Volunteer",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Volunteer",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Volunteer",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Volunteer",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Volunteer",
        },
      ],
    },
    {
      id: 3,
      date: "04/01/2021",
      attlist: [
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Core Member",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Core Member",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Core Member",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Volunteer",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Volunteer",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Member",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Volunteer",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Volunteer",
        },
        {
          name: "Seong-Hyeon Kavalioŭ",
          email: "abc@gmail.com",
          mobno: "1234567890",
          pos: "Volunteer",
        },
      ],
    },
  ];

  return (
    <MeetContainer>
      <MeetSubContainer>
        <MeetAssetEventContainer>
          <MeetAssetContainer>
            <LatestMeet />
          </MeetAssetContainer>
          <MeetEventContainer>
            <ScheduleMeet />
          </MeetEventContainer>
          <MeetEventContainer>
            <MeetAttendance />
          </MeetEventContainer>
        </MeetAssetEventContainer>
        <MeetMemberContainer>
          <AttendeesComponent
            attlist={list}
            date={true}
            icon={meetAttPage}
            heading="Meeting Attendees"
            resHeigh="650px;"
            widt="93%"
          />
        </MeetMemberContainer>
      </MeetSubContainer>
    </MeetContainer>
  );
};

export default Meet;
