import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from 'react'
import Landing from "./pages/Landing/Landing.page";
import Home from "./pages/Home/Home.page";
import Redirect from "./pages/Redirect/Redirect.page";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/home/" element={<Home />} />
        <Route path="/dashboard/" element={<Redirect />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
