import styled from "styled-components";

export const OrgContainer = styled.div`
   width: 100%;
   min-height: calc(100vh + 10rem);
   background-color: #eee;

   display: flex;
   justify-content: center;
   align-items: center;
   padding-bottom: 2rem;
   padding-top: 2rem;
`

export const OrgSubContainer = styled.div`
   width: 85%;
   height: 100%;
   display: flex;
   flex-direction: column;
   
   border-radius: 5px;
`

export const OrgAboutContainer = styled.div`
   height: 400px;
`

export const OrgAssetEventMemberContainer = styled.div`
   min-height: 50%;
   display: flex;
   justify-content: space-between;
`

export const OrgAssetEventContainer = styled.div`
   height: 650px;
   width: 25%;
   display: flex;
   flex-direction: column;
`

export const OrgAssetContainer = styled.div`
   width: 100%;
   height: 20%;
   border-radius: 5px;
`

export const OrgEventContainer = styled.div`
   margin-top: 1rem;
   width: 100%;
   height: 80%;
   border-radius: 5px;
`

export const OrgMemberContainer = styled.div`
   height: 650px;
   width: 74%;
   border-radius: 5px;
`