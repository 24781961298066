import styled from "styled-components";

export const heroContainer = styled.div`
   width: 100%;
   height: 80vh;
   background-image: url(${props => props.bgImage});
   background-size: 100%;
   background-repeat: no-repeat;

   display: flex;
   align-items: center;
`

export const heroTextContainer = styled.div`
   width: 50%;
   height: 70%;

   display: flex;
   justify-content: flex-end;
`

export const heroSubContainer = styled.div`
   width: 70%;
   height: 100%;
`

export const heroHeadingContainer = styled.div`
   padding: 2rem 1.6rem;
`

export const heroButtonContainer = styled.div`
   padding: 1rem 2rem;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   height: 35%;
   width: 55%;
`

export const heroHeading = styled.h1`
   font-size: 5rem;
   font-family: Martel;
   color: var(--green);
   letter-spacing: 2px;
   font-weight: bold;
`

export const heroContentContainer = styled.div`
   width: 100%;
   padding: 1rem 2rem;
`

export const heroContent = styled.span`
   width: 100%;
`