import React, { useState } from "react";
import * as Form from "../../Common/Form.styles";
import FormInput from "../../Common/FormInput";
import { useDispatch, useSelector } from 'react-redux';
import Request from "../../Common/Requests/request";
import * as Actions from "../../../redux/actions/Actions"
import At from "../../../assets/common/at.svg"
import About from "../../../assets/common/about.png"
import Url from "../../../assets/common/url.png"
import User from "../../../assets/common/user.svg"
import port from "../../../port"

// import FileUploadComponent from "../../Common/FileUploadComponent/FileUploadComponent"

const CreateOrgModal = () => {
   const { email, id } = useSelector(state => state.users)
   const dispatch = useDispatch();
   const orgData = {};
   const [org, setOrg] = useState({
      name: "",
      email: "",
      about: "",
      logo: "",
   });

   const handleChange = (event) => {
      const { name, value } = event.target;
      setOrg((prevValue) => ({ ...prevValue, [name]: value }));
   };


   function generateCode(email, name, about, logo) {
      let uniCode = '';
      let emname = email.concat(name);
      let emnabout = emname.concat(about)
      let emnabologo = emnabout.concat(logo);
      for (let i = 0; i < 6; i++) {
         let a = Math.random();
         a *= 100;
         a = Math.round(a);
         if (a >= emnabologo.length) {
            a -= emnabologo.length
         }
         uniCode += emnabologo[a];
      }
      return uniCode
   }

   const handleSubmit = (e) => {
      const uniCo = generateCode(org.email, org.name, org.about, org.logo.split("d/")[1].split("/view")[0])
      console.log(uniCo);

      e.preventDefault();
      let postOrgData = {
         name: org.name,
         email: org.email,
         about: org.about,
         logo: org.logo.split("d/")[1].split("/view")[0],
         uniCode: uniCo,
         admin: [
            { id: id, email: email }
         ]
      }

      console.log(postOrgData);
      Request.post("http://localhost:" + port + "/org/create", postOrgData)
         .then((res) => {
            console.log(res);
            if (res.message !== "Organization exist") {
               orgData.id = res.or.id
               orgData.name = res.or.name
               orgData.email = res.or.email
               orgData.about = res.or.about
               orgData.admin = res.or.admins
               orgData.uniCode = res.or.uniCode
               orgData.logo = res.or.logo
               console.log(orgData);
               dispatch(Actions.createOrg(orgData));
            }
         })
         .then(() => {
            let postOrgData = {
               organizations: [
                  { id: orgData.id, name: orgData.name }
               ]
            }
            Request.put("http://localhost:" + port + "/login/update/" + id, postOrgData)
               .then((res) => {
                  console.log(res.message);
                  dispatch(Actions.userDetailUpdate(res.user));
               })
         })
         .catch(err => console.log(err))
   };

   return (
      <Form.FormContainer action="" method="" onSubmit={handleSubmit}>
         <Form.FormHeading>Create Organisation</Form.FormHeading>

         <Form.FormText>
            Want to join existing organisation? <Form.FormLink to="/">Join Organisation</Form.FormLink>
         </Form.FormText>

         <FormInput
            icon={User}
            value={org.name}
            handleChange={handleChange}
            name="name"
            type="text"
            placeholder="Organization Name"
         // required
         />

         <FormInput
            icon={At}
            type="email"
            name="email"
            placeholder="Organization Email"
            // required
            value={org.email}
            handleChange={handleChange}
         />

         <FormInput
            icon={About}
            value={org.about}
            handleChange={handleChange}
            name="about"
            type="text"
            placeholder="About Organization"
         // required
         />

         <FormInput
            icon={Url}
            value={org.logo}
            handleChange={handleChange}
            name="logo"
            type="text"
            placeholder="Logo Link"
         // required
         />

         {/* <FileUploadComponent previewImg={org.previewImg} spanText="or drop files to upload" file={org.uploadFile} buttonText="Add Logo" id="selectFile" handleChange={handleChange} /> */}

         <Form.FormLink to="/" style={{ alignSelf: "flex-end" }} onClick={() => setOrg({ name: "", email: "", logo: "", about: "" })}>
            Reset
         </Form.FormLink>

         <Form.PrimaryButton type="submit">Create Organization</Form.PrimaryButton>
      </Form.FormContainer>
   );
};

export default CreateOrgModal;
