import React from "react";
import AboutComponent from "../../components/Organization/About/About.Component";
import CalendarComponent from "../../components/Dashboard/Calendar/Calendar.Component";
import AssetComponent from "../../components/Organization/Asset/Asset.Component";
import MemberComponent from "../../components/Organization/Member/Member.Component";
import * as tag from "./Organization.style";
import memberList from "../../assets/memberList.png";

const Organization = () => {
  const list = [
    {
      id: 100,
      name: "Kushdeep Singh",
      email: "abc@gmail.com",
      mobno: "1234567890",
      pos: "Core Member",
    },
    {
      id: 101,
      name: "Jasjeet Singh",
      email: "abc@gmail.com0",
      mobno: "1234567890",
      pos: "Core Member",
    },
    {
      id: 102,
      name: "Amritsinchan Kaur",
      email: "abc@gmail.com",
      mobno: "1234567890",
      pos: "Core Member",
    },
    {
      id: 103,
      name: "Divjot Kaur",
      email: "abc@gmail.com",
      mobno: "1234567890",
      pos: "Member",
    },
    {
      id: 104,
      name: "Harleen Kaur",
      email: "abc@gmail.com",
      mobno: "1234567890",
      pos: "Member",
    },
    {
      id: 105,
      name: "Ishvinder Singh",
      email: "abc@gmail.com",
      mobno: "1234567890",
      pos: "Member",
    },
    {
      id: 106,
      name: "Jasveen Kaur",
      email: "abc@gmail.com",
      mobno: "1234567890",
      pos: "Volunteer",
    },
    {
      id: 107,
      name: "Jasmeen Kaur",
      email: "abc@gmail.com",
      mobno: "1234567890",
      pos: "Volunteer",
    },
    {
      id: 108,
      name: "Jaskeerat Singh",
      email: "abc@gmail.com",
      mobno: "1234567890",
      pos: "Volunteer",
    },
    {
      id: 109,
      name: "Kashish Bagga",
      email: "abc@gmail.com",
      mobno: "1234567890",
      pos: "Volunteer",
    },
    {
      id: 110,
      name: "Mankirat Kaur",
      email: "abc@gmail.com",
      mobno: "1234567890",
      pos: "Volunteer",
    },
  ];

  return (
    <tag.OrgContainer>
      <tag.OrgSubContainer>
        <tag.OrgAboutContainer>
          <AboutComponent />
        </tag.OrgAboutContainer>
        <tag.OrgAssetEventMemberContainer>
          <tag.OrgAssetEventContainer>
            <tag.OrgAssetContainer>
              <AssetComponent />
            </tag.OrgAssetContainer>
            <tag.OrgEventContainer>
              <CalendarComponent />
            </tag.OrgEventContainer>
          </tag.OrgAssetEventContainer>
          <tag.OrgMemberContainer>
            <MemberComponent
              list={list}
              icon={memberList}
              heading="Divine Circle Members List"
              button="+ Add Member"
            />
          </tag.OrgMemberContainer>
        </tag.OrgAssetEventMemberContainer>
      </tag.OrgSubContainer>
    </tag.OrgContainer>
  );
};

export default Organization;
