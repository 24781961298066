//import { BrowserRouter, Route, Switch } from "react-router-dom";
import React, { Component } from 'react'
import FeatureComponent from '../../components/Landing/FeatureComponent/Feature.Component';
import HeroBoxComponent from '../../components/Landing/HeroBoxComponent/HeroBox.Component';

export default class Landing extends Component {
	render() {
		return (
			<>
				<HeroBoxComponent />
				<FeatureComponent />
			</>
		);
	}
}