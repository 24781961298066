import React from 'react'
import * as tag from './Funds.Style'
import fundOrgPage from "../../../assets/fundOrgPage.png"
import fundArrow from "../../../assets/rightArrow.png"

const FundsComponent = () => {
   return (
      <tag.FundContainer>
         <tag.FundAvatarContainer>
            <tag.FundAvatar src={fundOrgPage} />
         </tag.FundAvatarContainer>
         <tag.FundTextContainer>
            <tag.FundHeadingContainer>
               <tag.FundHeading>Funds</tag.FundHeading>
            </tag.FundHeadingContainer>
            <tag.FundDescContainer>
               <tag.FundDesc>
                  Balance: Rs 8000
               </tag.FundDesc>
            </tag.FundDescContainer>
            <tag.FundArrowContainer>
               <tag.FundArrow src={fundArrow} /> 
            </tag.FundArrowContainer>
         </tag.FundTextContainer>
      </tag.FundContainer>
   )
}

export default FundsComponent
