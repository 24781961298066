import styled from "styled-components"

export const Container = styled.div`
   width: 100%;
   padding: 20px 30px;

   display: flex;
   justify-content: space-between;
`

export const ImgContainer = styled.div`
   width: 26%;
   height: 70px;
`

export const Img = styled.img`
   width: 100%;
   height: 100%;
`

export const TextContainer = styled.div`
   width: 70%;
   display: flex;
   flex-direction: column;
   justify-content: center;
`

export const Text = styled.p`
   &:nth-child(1){
      font-size: 1.7rem;
      font-weight: 600;
   }
   &:nth-child(2){
      margin-top: 0.5rem;
      font-size: 1.5rem;
   }
`