import React from 'react'
import EventComponent from '../../components/Event/Event.component'

const EventSummary = () => {
   return (
      <>
         <EventComponent />  
      </>
   )
}

export default EventSummary
