import styled from "styled-components";

export const Container = styled.div`
   height: 83vh;
   width: 100%;

   background-color: #eee;
   display: flex;
   justify-content: center;
   align-items: center;
`

export const TaskContainer = styled.div`
   height: 95%;
   width: 1228.8px;
   background-color: white;
   display: flex;
   flex-direction: column;
   justify-content: center;
   &:nth-child(3){
      margin-top: 1.5rem;
   }
`

export const TaskHeadingContainer = styled.div`
   margin-bottom: 2rem;
   margin-left: 5rem;
   margin-top: 2rem;
   display: flex;
   align-items: center;
`

export const TaskIcon = styled.img`
   width: 60px;
   height: 60px;
`

export const TaskHeading = styled.h1`
   margin-left: 2rem;
   font-size: 2.5rem;
   font-weight: bold;
   color: var(--green);
`

export const TaskListTable = styled.div`
   height: 400px;
   width: 1200px;
   padding-right: 25px;
   overflow-y: auto;
   display: flex;
   flex-direction: column;
   align-items: center;
`
export const TaskRowContainer = styled.div`
   width: 1166px;
`

export const TaskListTableRow = styled.div`
   display: flex;
   height: 30px;
   width: 95%;
   margin-left: 3.4rem;
   justify-content: center;
   ${TaskListTable} &{
      margin-left: 1rem;
      height: 80px;
      /* width: 95%; */
      /* justify-content: space-evenly; */
   }
`

export const TaskListTableCell = styled.div`
   height: 80px;
   line-height: 20px;
   
   ${TaskListTable} ${TaskListTableRow} &:nth-child(1){
      width: 10%;
      display: flex;
      justify-content: center;
   }
   ${TaskListTable} ${TaskListTableRow} &:nth-child(2){
      width: 20%;
      display: flex;
      justify-content: center;
   }
   ${TaskListTable} ${TaskListTableRow} &:nth-child(3){
      display: flex;
      justify-content: center;
      width: 10%;
   }
   ${TaskListTable} ${TaskListTableRow} &:nth-child(4){
      display: flex;
      justify-content: center;
      width: 15%;
   }
   ${TaskListTable} ${TaskListTableRow} &:nth-child(5){
      width: 15%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
   }
   ${TaskListTable} ${TaskListTableRow} &:nth-child(6){
      width: 15%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
   }
   ${TaskListTable} ${TaskListTableRow} &:nth-child(7){
      width: 25%;
   }
   ${TaskListTable} ${TaskListTableRow} &:nth-child(8){
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
   }
`

export const TaskListTableHead = styled.div`
   font-weight: bold;
   &:nth-child(1){
      width: 10%;
      display: flex;
      justify-content: center;
   }
   &:nth-child(2){
      width: 20%;
      display: flex;
      justify-content: center;
   }
   &:nth-child(3){
      display: flex;
      justify-content: center;
      width: 10%;
   }
   &:nth-child(4){
      display: flex;
      justify-content: center;
      width: 15%;
   }
   &:nth-child(5){
      width: 15%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
   }
   &:nth-child(6){
      width: 15%;
      display: flex;
      justify-content: center;
   }
   &:nth-child(7){
      width: 25%;
      display: flex;
      justify-content: center;
   }
   &:nth-child(8){
      width: 10%;
      display: flex;
      justify-content: center;
   }
`

export const Span = styled.span`
   
`