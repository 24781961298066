import React from 'react'
import { useSelector } from 'react-redux'
import HeaderComponent from '../../components/Common/HeaderComponent/Header.Component';
import NavBarComponent from '../../components/Common/NavBarComponent/NavBar.Component';
import Funds from '../Funds/Funds.page';
import Meet from '../Meet/Meet.page';
import Organization from '../Organization/Organization.page';
import Dashboard from "../Dashboard/Dashboard.page";
import EventSummary from "../EventSummary/EventSummary.page";

const Redirect = () => {
	const page = useSelector((state) => state.activeNav);
	const {users} = useSelector(state => state)

   if(Object.keys(users).length === 0){
      window.location = "/"
   }

	const findComponent = (page) => {
		switch (page) {
			case "dashboard" : return <Dashboard />;
			case "organization": return <Organization />;
			case "fund": return <Funds />;
			case "meet": return <Meet />;
			case "eventsummary": return <EventSummary />;
			default: return "404";
		}
	}

	return (
		<>
			<HeaderComponent />
			<NavBarComponent />
			{findComponent(page)}
		</>
	)
}

export default Redirect
