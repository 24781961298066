import React from "react";
import * as tag from "./Event.style";
import EventListIcon from "../../assets/EventListIcon.png";
import edit from "../../assets/common/edit.svg";

const EventComponent = () => {
	const Tasks = [
		{
			name: "Shri Akhand Path Sahib ",
			date: "1/12/2021",
			budget: "30000",
			em: "Kushdeep Walia",
			ec: ["Jasmeen Kaur", "Harleen Kaur"],
			members: [
				"Divjot Kaur",
				"Mankirat Kaur",
				"Ishwinder Singh",
				"Kawal Kaur",
			],
			feedback: "Great Event. Good coordination among members. Keep it up.",
		},
		{
			name: "Shaheedi Samagam ",
			date: "25/11/2021",
			budget: "27000",
			em: "Kushdeep Walia",
			ec: ["Jasmeen Kaur", "Harleen Kaur"],
			members: [
				"Divjot Kaur",
				"Mankirat Kaur",
				"Ishwinder Singh",
				"Kawal Kaur",
			],
			feedback: "Great Event. Good coordination among members. Keep it up.",
		},
		{
			name: "Rahbar-e-Azam ",
			date: "12/10/2021",
			budget: "5000",
			em: "Kushdeep Walia",
			ec: ["Jasmeen Kaur", "Harleen Kaur"],
			members: [
				"Divjot Kaur",
				"Mankirat Kaur",
				"Ishwinder Singh",
				"Kawal Kaur",
			],
			feedback: "Great Event. Good coordination among members. Keep it up.",
		},
		{
			name: "Fundamentals of Sikhi ",
			date: "12/09/2021",
			budget: "3500",
			em: "Kushdeep Walia",
			ec: ["Jasmeen Kaur", "Harleen Kaur"],
			members: [
				"Divjot Kaur",
				"Mankirat Kaur",
				"Ishwinder Singh",
				"Kawal Kaur",
			],
			feedback: "Great Event. Good coordination among members. Keep it up.",
		},
		{
			name: "Speak to lead ",
			date: "12/08/2021",
			budget: "1000",
			em: "Kushdeep Walia",
			ec: ["Jasmeen Kaur", "Harleen Kaur"],
			members: [
				"Divjot Kaur",
				"Mankirat Kaur",
				"Ishwinder Singh",
				"Kawal Kaur",
			],
			feedback: "Great Event. Good coordination among members. Keep it up.",
		},
		{
			name: "Kavi Darbar ",
			date: "12/07/2021",
			budget: "34000",
			em: "Kushdeep Walia",
			ec: ["Jasmeen Kaur", "Harleen Kaur"],
			members: [
				"Divjot Kaur",
				"Mankirat Kaur",
				"Ishwinder Singh",
				"Kawal Kaur",
			],
			feedback: "Great Event. Good coordination among members. Keep it up.",
		},
	];
	return (
		<tag.Container>
			<tag.TaskContainer>
				<tag.TaskHeadingContainer>
					<tag.TaskIcon src={EventListIcon} />
					<tag.TaskHeading>Event Summary</tag.TaskHeading>
				</tag.TaskHeadingContainer>
				<tag.TaskRowContainer>
				 	<tag.TaskListTableRow>
						<tag.TaskListTableHead>Date</tag.TaskListTableHead>
						<tag.TaskListTableHead>Name</tag.TaskListTableHead>
						<tag.TaskListTableHead>Budget</tag.TaskListTableHead>
						<tag.TaskListTableHead>Event Manager</tag.TaskListTableHead>
						<tag.TaskListTableHead>Event Coordinator</tag.TaskListTableHead>
						<tag.TaskListTableHead>Members</tag.TaskListTableHead>
						<tag.TaskListTableHead>Feedback</tag.TaskListTableHead>
						<tag.TaskListTableHead>&nbsp;</tag.TaskListTableHead>
				 	</tag.TaskListTableRow>
				</tag.TaskRowContainer>
				<tag.TaskListTable>
					{Tasks.map((e) => {
						return (
							<tag.TaskListTableRow>
								<tag.TaskListTableCell>{e.date}</tag.TaskListTableCell>
								<tag.TaskListTableCell>{e.name}</tag.TaskListTableCell>
								<tag.TaskListTableCell>{e.budget}</tag.TaskListTableCell>
								<tag.TaskListTableCell>{e.em}</tag.TaskListTableCell>
								<tag.TaskListTableCell>
									{e.ec.map((a) => {
										return <tag.Span>{a}</tag.Span>;
									})}
								</tag.TaskListTableCell>
								<tag.TaskListTableCell>
									{e.members.map((a) => {
										return <tag.Span>{a}</tag.Span>;
									})}
								</tag.TaskListTableCell>
								<tag.TaskListTableCell>{e.feedback}</tag.TaskListTableCell>
								<tag.TaskListTableCell>
									<img src={edit} alt="Edit Icon" />
								</tag.TaskListTableCell>
							</tag.TaskListTableRow>
						);
					})}
				</tag.TaskListTable>
			</tag.TaskContainer>
		</tag.Container>
	);
};

export default EventComponent;
