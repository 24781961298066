import { Avatar } from "@shopify/polaris";
import React from "react";
import {
  OrgAvatarContainer,
  OrgContainer,
  OrgDesc,
  OrgDescContainer,
  OrgHeading,
  OrgHeadingContainer,
  OrgTextContainer,
} from "./Organization.Style";

const OrganizationComponent = () => {
  return (
    <OrgContainer>
      <OrgAvatarContainer>
        <Avatar size="large" customer={true} />
      </OrgAvatarContainer>
      <OrgTextContainer>
        <OrgHeadingContainer>
          <OrgHeading>Divine Circle</OrgHeading>
        </OrgHeadingContainer>
        <OrgDescContainer>
          <OrgDesc>Divinity Society of GTBIT</OrgDesc>
        </OrgDescContainer>
      </OrgTextContainer>
    </OrgContainer>
  );
};

export default OrganizationComponent;
