import React, { useState } from "react";
import * as Form from "../../Common/Form.styles";
import { Input, InputContainer } from "./Join.style";
import { useDispatch, useSelector } from 'react-redux';
import Request from "../../Common/Requests/request";
import * as Actions from "../../../redux/actions/Actions"
import port from "../../../port"

const JoinOrgModal = () => {
   let orgData = {};
   const user = useSelector(state => state.users)
   const dispatch = useDispatch();
   const [code, setCode] = useState({
      first: "",
      second: "",
      third: "",
      fourth: "",
      fifth: "",
      sixth: "",
      lastCount: 0
   });

   const last = [
      "first",
      "second",
      "third",
      "fourth",
      "fifth",
      "sixth"
   ];

   const handleChange = (event) => {
      const { name, value } = event.target;
      if (last[code.lastCount] !== "sixth") {
         shiftFocus(last[code.lastCount + 1]);
         setCode((prevValue) => ({ ...prevValue, lastCount: code.lastCount + 1 }));
      }
      setCode((prevValue) => ({ ...prevValue, [name]: value }));
   };

   function shiftFocus(last) {
      document.getElementById(last).focus();
   };

   const handleSubmit = (e) => {

      e.preventDefault();
      let postJoinCode = {
         code: code.first + code.second + code.third + code.fourth + code.fifth + code.sixth,
         id: user.id,
         name: user.name
      }
      Request.post("http://localhost:" + port + "/org/join", postJoinCode)
         .then((res) => {
            console.log(res);
            if (((res.message !== "Wrong Code" || res.message !== "You are already a member in this organization") || res.message !== "You are already a volunteer in this organization") & res.or !== undefined) {
               orgData.id = res.or.id
               orgData.name = res.or.name
               orgData.email = res.or.email
               orgData.about = res.or.about
               orgData.admin = res.or.admins
               orgData.uniCode = res.or.uniCode
               orgData.logo = res.or.logo
               console.log(orgData);
               dispatch(Actions.orgDetailUpdate(orgData));
            }
            if (((res.message === "You are already a member in this organization" || res.message === "You are already a volunteer in this organization") || res.message === "Successfully Joined") && res.or !== undefined) {
               let postOrgData = {
                  organizations: [
                     { id: res.det.id, name: res.det.name }
                  ]
               }
               Request.put("http://localhost:" + port + "/login/update/" + user.id, postOrgData)
                  .then((res) => {
                     console.log(res.message);
                     dispatch(Actions.userDetailUpdate(res.user));
                  })
            }
         })
         .catch(err => console.log(err))
   };

   return (
      <Form.FormContainer action="" method="" onSubmit={handleSubmit}>
         <Form.FormHeading>Join Organisation</Form.FormHeading>

         <Form.FormText>
            Enter Organisation Code. No Organization?<Form.FormLink to="/">Create Organization</Form.FormLink>
         </Form.FormText>

         <InputContainer>
            <Input type="text" onChange={handleChange} value={code.first} id="first" name="first" maxLength={1} />
            <Input type="text" onChange={handleChange} value={code.second} id="second" name="second" maxLength={1} />
            <Input type="text" onChange={handleChange} value={code.third} id="third" name="third" maxLength={1} />
            <Input type="text" onChange={handleChange} value={code.fourth} id="fourth" name="fourth" maxLength={1} />
            <Input type="text" onChange={handleChange} value={code.fifth} id="fifth" name="fifth" maxLength={1} />
            <Input type="text" onChange={handleChange} value={code.sixth} id="sixth" name="sixth" maxLength={1} />
         </InputContainer>

         <Form.FormLink to="/" style={{ alignSelf: "flex-end" }} onClick={() => setCode({ first: "", second: "", third: "", fourth: "", fifth: "", sixth: "", lastCount: 0 })}>
            Reset
         </Form.FormLink>

         <Form.PrimaryButton type="submit">Join Organization</Form.PrimaryButton>
      </Form.FormContainer>
   );
};

export default JoinOrgModal;
