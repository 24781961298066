import React from 'react'
import { Container, Img, ImgContainer, Text, TextContainer } from './MeetAttendance.style'
import attOrgPage from "../../../assets/attOrgPage.png"

const MeetAttendance = () => {
   return (
      <Container>
         <ImgContainer>
            <Img src={attOrgPage} />
         </ImgContainer>
         <TextContainer>
            <Text>Your Attendance</Text>
            <Text>Attended 5 meets out of 8</Text>
         </TextContainer>
      </Container>
   )
}

export default MeetAttendance
