import {combineReducers} from "redux"
import * as Reducers from "./Reducer"

const reducers = combineReducers({
    users: Reducers.account,
    orgs: Reducers.organi,
    selectedOrg: Reducers.orgSelection,
    activeNav: Reducers.activeNav
})

export default reducers