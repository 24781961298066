import styled from "styled-components";

export const OrgContainer = styled.div`
   width: 100%;
   height: 100%;
   background-color: var(--green);
   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
   border-radius: 5px;
   color: white;

   display: flex;
   justify-content: center;
   align-items: center;
`

export const OrgAvatarContainer = styled.div`
   width: 30%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
`

export const OrgTextContainer = styled.div`
   width: 70%;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
`

export const OrgHeadingContainer = styled.div`
   width: 100%;
   margin-bottom: 0.5rem;
`

export const OrgHeading = styled.h2`
   font-size: 2rem;
   font-weight: bold;
`

export const OrgDescContainer = styled.div`
   
`

export const OrgDesc = styled.span`
   
`