import styled from "styled-components";
import { Container } from '../LatestMeet/LatestMeet.style'

export const ScheContainer = styled(Container)`
   display: flex;
   width: 100%;
   flex-direction: row;
   justify-content: space-evenly;
   align-items: center;
`

export const Span = styled.span`
   width: 70%;
   margin-left: 1rem;
   font-size: 1.8rem;
`

export const Button = styled.button`
   width: 30%;
   padding: 7px 12px;
   background-color: var(--green);
   border-radius: 5px;
   color: white;
   font-size: 1.8rem;
   font-weight: 600;
`