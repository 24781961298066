import React from "react";
import {
  Container,
  ComIcon,
  IconContainer,
  IconNameContainer,
  Heading,
  MemberContainer,
  MemberListContainer,
  NameContainer,
  PostContainer,
  SelectAllContainer,
  Text,
  TextContainer,
  InputAvatarNameContainer,
  AvatarContainer,
  Select,
  InputContainer,
  ComInput,
  MemNameContainer,
  Name,
  PostInputContainer,
  PostButtonContainer,
  PostButton,
} from "./PostContribution.style";
import Contri from "../../../assets/common/contributon.svg";
import Rupee from "../../../assets/common/rupee.png";
import { Avatar } from "@shopify/polaris";
import { FormInputContainer, Icon, Input } from "../../Common/Form.styles";

const PostContribution = () => {
  const MemList = [
    "Kushdeep Singh",
    "Jasveen Kaur",
    "Jasmeen Kaur",
    "Harleen Kaur",
    "Ishvinder Singh",
    "Divjot Kaur",
    "Mankirat Kaur",
    "Jaskeerat Singh",
    "Jasjeet Singh",
    "Kashish",
  ];

  function selectAll() {
    document.getElementById("selectID").addEventListener("click", () => {
      console.log(document.getElementsByClassName("selectClass"));
    });
  }

  return (
    <Container>
      <IconNameContainer>
        <IconContainer>
          <ComIcon src={Contri} />
        </IconContainer>
        <NameContainer>
          <Heading>Post Contribution</Heading>
        </NameContainer>
      </IconNameContainer>
      <TextContainer>
        <Text>
          Select names to notify them to pay contribution for organisation
          funding.
        </Text>
      </TextContainer>
      <MemberContainer>
        <SelectAllContainer>
          <Select id="selectID" onClick={() => selectAll()}>
            Select All
          </Select>
        </SelectAllContainer>
        <MemberListContainer>
          {MemList.map((name, count) => {
            return (
              <InputAvatarNameContainer key={count}>
                <InputContainer>
                  <ComInput type="checkbox" className="selectClass" />
                </InputContainer>
                <AvatarContainer>
                  <Avatar customer={true} size="medium" />
                </AvatarContainer>
                <MemNameContainer>
                  <Name>{name}</Name>
                </MemNameContainer>
              </InputAvatarNameContainer>
            );
          })}
        </MemberListContainer>
      </MemberContainer>
      <PostContainer>
        <PostInputContainer>
          <FormInputContainer widt="100%">
            <Icon src={Rupee} up="-50%" />
            <Input widt="100%" margin="no" placeholder="Amount" required />
          </FormInputContainer>
        </PostInputContainer>
        <PostButtonContainer>
          <PostButton>Post</PostButton>
        </PostButtonContainer>
      </PostContainer>
    </Container>
  );
};

export default PostContribution;
