import React from 'react'
import { useSelector } from "react-redux";
import HeaderComponent from '../../components/Common/HeaderComponent/Header.Component';
import NewHomeComponent from '../../components/Home/NewHome/NewHome.Component';
import OldHomeComponent from '../../components/Home/OldHome/OldHome.Component';

const Home = () => {
   let old = true
   const {users, orgs} = useSelector(state => state)

   if(Object.keys(users).length === 0){
      window.location = "/"
   }else if(orgs.length === 0){
      old = false;
   }
   else{
      old = true;
   }

   return (
      <>
         <HeaderComponent NoSelect={true} />
      {
         old === true ?
         <OldHomeComponent /> :
         <NewHomeComponent />
      }
      </>
   )
}

export default Home
