import styled from "styled-components";

export const AboutContainer = styled.div`
   width: 100%;
   height: 95%;
   display: flex;
   flex-direction: column;
   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
   border-radius: 5px;
   background-color: white;
   position: relative;
`

export const AboutOrgBg = styled.div`
   width: 100%;
   height: 55%;
`

export const AboutOrgBgImg = styled.img`
   width: 100%;
   height: 100%;
`

export const AboutOrgInfo = styled.div`
   width: 100%;
   height: 45%;
   display: flex;
   justify-content: center;
`

export const AboutOrgInfoLeftContainer = styled.div`
   height: 100%;
   width: 25%;
   display: flex;
   justify-content: flex-end;
   padding-bottom: 2rem;
   flex-direction: column;
`

export const AboutOrgName = styled.div`
   font-weight: bold;
   font-size: 2.5rem;
   color: var(--green);
`

export const AboutOrgShortDesc = styled.div`
   padding-top: 1.5rem;
`

export const AvatarContainer = styled.div`
   padding: 5px;
   background-color: white;
   position: absolute;
   top: 50%;
   left: 0;
   transform: translate(50%,-40%);
   border-radius: 50%;
   border: 0.5px solid grey
`

export const AboutOrgInfoRightContainer = styled.div`
   height: 100%;
   width: 65%;

   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
`

export const AboutOrgAboutHeadingContainer = styled.div`
   
`

export const AboutOrgAboutHeading = styled.div`
   font-size: 2rem;
   font-weight: bold;
`

export const AboutOrgAboutDescContainer = styled.div`
   
`

export const AboutOrgAboutDesc = styled.div`
   
`

export const AboutOrgSocialContainer = styled.div`
   display: flex;
`

export const AboutOrgSocialItem = styled.div`
   display: flex;
   align-items: center;
   
   &:nth-child(2){
      padding: 0 2rem;
   }
`

export const AboutOrgSocialIcon = styled.img`
   
`

export const AboutOrgSocialUserName = styled.div`
   padding-left: 1rem;
`