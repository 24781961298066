import post from "../PostDataFunction/postdata";
import put from "../putDataFunction/putdata";
import get from "../GetDataFunction/getdata";
import del from "../deleteDataFunction/deleteData";

const Request = {
   post,
   put,
   get,
   del
}

export default Request