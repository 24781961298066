import styled from "styled-components";

export const NewContainer = styled.div`
   height: 90vh;
   width: 100%;

   display: flex;
   justify-content: center;
   align-items: center;
`

export const NewSubContainer = styled.div`
   height: 90%;
   width: 90%;

   display: flex;
   justify-content: center;
   align-items: center;
`

export const NewLeftContainer = styled.div`
   height: 100%;
   width: 30%;
`

export const NewLeftImage = styled.img`
   height: 70%;
   width: 100%;
`

export const NewRightContainer = styled.div`
   height: 100%;
   width: 40%;
   margin-left: 1rem;
   padding-top: 1rem
`

export const NewRightHeading = styled.h1`
   font-size: 3rem;
`