import styled from "styled-components";

export const Container = styled.div`
   background-color: white;
   width: 100%;
   min-height: 100%;
   display: flex;
   justify-content: center;
`

export const SubContainer = styled.div`
   width: ${props => props.widt ? props.widt: "90%"};
   min-height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
`

export const TextIconAddContainer = styled.div`
   width: 90%;
   height: 110px;
   padding: 15px 0;
   display: flex;
   justify-content: space-between;
   align-items: center;
`

export const AddContainer = styled.div`
   display: flex;
`

export const TextIconContainer = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
`

export const TextContainer = styled.div`
   display: flex;
   padding-left: 15px;
`

export const IconContainer = styled.div`
   display: flex;
`

export const Icon = styled.img`
   display: flex;
`

export const Text = styled.h3`
   font-size: 1.7rem;
   font-weight: bold;
`

export const Add = styled.span`
   font-size: 1.7rem;
   font-weight: bold;
   color: var(--green);
`

export const ResourceListContainer = styled.div`
   width: ${props => props.widt ? props.widt: "90%"};
   height: ${props => props.heigh ? props.heigh: "519px"};
   overflow-y: auto;
`

export const ItemInfo = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
`

export const NameEmail = styled.div`
   display: flex;
   width: 30%;
   flex-direction: column;
`

export const NumPos = styled.div`
   display: flex;
   width: 30%;
   
`

export const Num = styled.span`
   width: 100%;
`

export const Pos = styled.span`
   width: 100%;
   display: flex;
   justify-content: center;
`