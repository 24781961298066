import React from "react";
import * as tag from "./Feature.Style";
import Features from "../../../assets/Features.svg";

const FeatureComponent = () => {
  return (
    <tag.FeatureContainer>
      <tag.FeatureSubContainer>
        <tag.FeatureHeadingContainer>
          <tag.FeatureHeading>Features</tag.FeatureHeading>
        </tag.FeatureHeadingContainer>
        <tag.FeatureTextContainer>
          <tag.FeatureText>
            EveMan offers features like Funds Managements to keep track of all
            expenses, Meetings and Attendance to post team meetings information
            and keep record of attendees, Assigned Task list to create a task
            and assign to team members, Survey and Polls to take views of team
            members while making decisions and Records of Past Events in
            chunkable format.
          </tag.FeatureText>
        </tag.FeatureTextContainer>
        <tag.FeatureImageContainer>
          <tag.FeatureImage src={Features} />
        </tag.FeatureImageContainer>
      </tag.FeatureSubContainer>
    </tag.FeatureContainer>
  );
};

export default FeatureComponent;
