import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from "../../../redux/actions/Actions"
import { Anchor, NavContainer, NavItemContainer, NavItemText, NavSelectedItemContainer, NavSubContainer } from './NavBar.Style';

const NavBarComponent = () => {
   const dispatch = useDispatch();

   const page = useSelector((state) => state.activeNav);

   const updateActive = (page) => {
      dispatch(Actions.activeNav(page));
   }

   const NavItemList = [
      {link: "dashboard" , display: "Dashboard"},
      {link: "organization" , display: "Organisation"},
      {link: "fund" , display: "Funds"},
      {link: "meet" , display: "Meetings & Attendance"},
      {link: "eventsummary" , display: "Event Summary"}
   ]

   const NavItems = NavItemList.map((item, count) => {
      if(item.link === page)
         return (
            <NavSelectedItemContainer key={count++}>
               <Anchor onClick={() => updateActive(item.link)}>
                  <NavItemText>{item.display}</NavItemText>
               </Anchor>
            </NavSelectedItemContainer>
         )
      else
         return (
            <NavItemContainer key={count++}>
               <Anchor onClick={() => updateActive(item.link)}>
                  <NavItemText>{item.display}</NavItemText>
               </Anchor>
            </NavItemContainer>
         )
   })

   return (
      <NavContainer>
         <NavSubContainer>
            <NavItemContainer>
               {NavItems}
            </NavItemContainer>
         </NavSubContainer>
      </NavContainer>
   )
}

export default NavBarComponent
