import styled from "styled-components";

export const HeaderContainer = styled.div`
   width: 100%;
   height: 10vh;
   box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
   display: flex;
   justify-content: space-between;
   padding: 0 2rem;
   align-items: center;
`

export const HeaderNameIconContainer = styled.div`
   height: 80%;
   width: 12%;
   display: flex;
   justify-content: space-around;
   align-items: center;
   cursor: pointer;
`

export const HeaderIcon = styled.img`
   height: 85%;
`

export const HeaderWebName = styled.h3`
   /* width: 60%; */
   padding-top: 0.5rem;
   height: 100%;
   display: flex;
   align-items: center;
   color: var(--green);
   font-family: Martel;
   font-size: 2.5rem;
   font-weight: bold;
`

export const HeaderUserName = styled.div`
   height: 80%;
   /* width: 12%; */
   display: flex;
   justify-content: space-around;
   align-items: center;
`

export const HeaderName = styled.h3`
   padding-top: 0.5rem;
   padding-left: 10px;
   font-family: Martel;
   font-size: 2rem;
`

export const SelectContainer = styled.div`
   width: 30%;
`