import styled from "styled-components";

export const PollContainer = styled.div`
   width: 100%;
   height: 40%;
   background-color: white;
   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
   border-radius: 5px;

   display: flex;
   justify-content: space-evenly;
   flex-direction: column;
`

export const PollTopicNameIcon = styled.div`
   display: flex;
   height: 20%
`

export const PollAvatarContainer = styled.div`
   width: 30%;
   height: 100%;
   display: flex;
   padding: 0 1rem;
   justify-content: center;
   align-items: flex-start;
`

export const PollAvatar = styled.img`
   
`

export const PollTextContainer = styled.div`
   width: 70%;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
`

export const PollHeadingContainer = styled.div`
   width: 100%;
`

export const PollHeading = styled.h2`
   font-size: 2rem;
   font-weight: bold;
`

export const PollCreateContainer = styled.div`
   margin-top: 5px;
`

export const PollCreate = styled.span`
   color: var(--green);
`

export const PollDescContainer = styled.div`
   margin-top: 0.5rem;
   margin-bottom: 1rem;
   display: flex;
   flex-direction: column;
   width: 100%;
   justify-content: center;
   align-items: center;
`

export const PollDesc = styled.span`
   width: 90%;
   text-align: center;
   font-size: 1.6rem;
`

export const PollOptionsContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: center;
`

export const PollOption = styled.div`
   padding: 0.25rem 1rem;
   border: 0.5px solid var(--green);
   border-radius: 10px;
   cursor: pointer;

   &:nth-child(2){
      margin: 1rem 0;
   }

   &:hover{
      background-color: var(--green);
      color: white;
      font-weight: bold;
   }
`

export const PollArrowContainer = styled.div`
   display: flex;
   width: 100%;
   justify-content: flex-end;
   align-items: flex-end;
   padding-right: 3rem;
`

export const PollArrow = styled.img`
   
`