import React from "react";
import AssetOrgPage from "../../../assets/downloadAsset.png";
import * as tag from "./Asset.Style";

const AssetComponent = () => {
  return (
    <tag.AssetContainer>
      <tag.AssetAvatarContainer>
        <tag.AssetAvatar src={AssetOrgPage} />
      </tag.AssetAvatarContainer>
      <tag.AssetTextContainer>
        <tag.AssetHeadingContainer>
          <tag.AssetHeading>Download Assets</tag.AssetHeading>
        </tag.AssetHeadingContainer>
        <tag.AssetDescContainer>
          <tag.AssetDesc>
            Download DC logo, banners, infographics layout.
          </tag.AssetDesc>
        </tag.AssetDescContainer>
        <tag.AssetArrowContainer>
          <tag.AssetArrow>Download</tag.AssetArrow>
        </tag.AssetArrowContainer>
      </tag.AssetTextContainer>
    </tag.AssetContainer>
  );
};

export default AssetComponent;
