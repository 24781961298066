import React, { useCallback, useState } from "react";
import * as tag from "./Task.Style";
import { Select } from "@shopify/polaris";
import taskOrgPage from "../../../assets/taskOrgPage.png";
import plus from "../../../assets/common/plus.svg";
import edit from "../../../assets/common/edit.svg";
import del from "../../../assets/common/del.svg";

const TaskComponent = () => {
  const [selected, setSelected] = useState("today");

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const options = [
    { label: "Akhand Paath Sahib Ji", value: "Akhand Paath Sahib Ji" },
    { label: "Shaheedi Samagam", value: "Shaheedi Samagam" },
    { label: "Rahbar-e-Azam", value: "Rahbar-e-Azam" },
  ];

  const PendingTasks = [
    {
      taskName:
        "Send reminders to contact list regarding registration and participation.",
      completeBy: "14/01/2022",
      assignedTo: "Kushdeep Walia",
    },
    {
      taskName:
        "Finalize posters & Infographics and proofread printed materials",
      completeBy: "14/01/2022",
      assignedTo: "Jasveen Kaur",
    },
    {
      taskName: "Coordinate with event suppliers (catering, equipment, etc). ",
      completeBy: "14/01/2022",
      assignedTo: "Harleen Kaur",
    },
    {
      taskName: "Create an event budget and maintain finance record",
      completeBy: "14/01/2022",
      assignedTo: "Kushdeep Walia",
    },
    {
      taskName: "Arrange sponsorships and speakers for your event. ",
      completeBy: "12/01/2022",
      assignedTo: "Divjot Kaur",
    },
    {
      taskName: "Brand your event and begin publicity. Create Posters. ",
      completeBy: "12/01/2022",
      assignedTo: "Kushdeep Walia",
    },
  ];

  const CompletedTasks = [
    {
      taskName:
        "Design a new DC logo and further finalise it with the help of team members",
      completeBy: "12/01/2022",
      assignedTo: "Kushdeep Walia",
    },
    {
      taskName:
        "Proper Invitation letters to be made for different cllg societies like anhad, vismad",
      completeBy: "12/01/2022",
      assignedTo: "Jasveen Kaur",
    },
    {
      taskName:
        "I-card designing for event and send to the vendor for printing ",
      completeBy: "12/01/2022",
      assignedTo: "Harleen Kaur",
    },
    {
      taskName:
        "Collection of jackets and I-cards to be worn on the event itself",
      completeBy: "12/01/2022",
      assignedTo: "Divjot Kaur",
    },
    {
      taskName:
        "PR team to be in action for publising the event through online platform",
      completeBy: "12/01/2022",
      assignedTo: "Kushdeep Walia",
    },
    {
      taskName: "Print Permission letters for all the series of events ",
      completeBy: "12/01/2022",
      assignedTo: "Kushdeep Walia",
    },
  ];

  // const Pending =

  return (
    <tag.TaskBoxContainer>
      <tag.TaskIconNameOption>
        <tag.TaskIconContainer>
          <tag.TaskIcon src={taskOrgPage} />
        </tag.TaskIconContainer>
        <tag.TaskNameOptionContainer>
          <tag.TaskNameContainer>
            <tag.TaskName>Event Name :</tag.TaskName>
          </tag.TaskNameContainer>
          <tag.TaskOptionContainer>
            <Select
              options={options}
              onChange={handleSelectChange}
              value={selected}
            />
          </tag.TaskOptionContainer>
        </tag.TaskNameOptionContainer>
        <tag.TaskEditingButtonContainer>
          <tag.TaskEditingButton>
            <tag.TaskEditingButtonGrp>
              <tag.TaskEditingButtonIcon src={plus} />
              <tag.TaskEditingButtonText>Create</tag.TaskEditingButtonText>
            </tag.TaskEditingButtonGrp>
          </tag.TaskEditingButton>
          <tag.TaskEditingButton>
            <tag.TaskEditingButtonGrp>
              <tag.TaskEditingButtonIcon src={edit} />
              <tag.TaskEditingButtonText>Edit</tag.TaskEditingButtonText>
            </tag.TaskEditingButtonGrp>
          </tag.TaskEditingButton>
          <tag.TaskEditingButton>
            <tag.TaskEditingButtonGrp>
              <tag.TaskEditingButtonIcon src={del} />
              <tag.TaskEditingButtonText>Delete</tag.TaskEditingButtonText>
            </tag.TaskEditingButtonGrp>
          </tag.TaskEditingButton>
        </tag.TaskEditingButtonContainer>
      </tag.TaskIconNameOption>
      <tag.TaskDescContainer>
        <tag.TaskDescAboutContainer>
          <tag.TaskDescHeadingContainer>
            <tag.TaskDescHeading>Description</tag.TaskDescHeading>
          </tag.TaskDescHeadingContainer>
          <tag.TaskDescDescContainer>
            <tag.TaskDescAbout>
              Sampooranta of Sri Akhand Paath Sahib Ji.
              <br /> Date : February 1, 2022
              <br /> ️Time : 11 am onwards
              <br /> ️Venue : BVS Auditorium
            </tag.TaskDescAbout>
          </tag.TaskDescDescContainer>
        </tag.TaskDescAboutContainer>
        <tag.TaskDescEmEcContainer>
          <tag.TaskDescHeadingContainer>
            <tag.TaskDescHeading>Management</tag.TaskDescHeading>
          </tag.TaskDescHeadingContainer>
          <tag.TaskDescDescContainer>
            <tag.TaskDescAbout>
              <tag.TaskDescEmEcHeading>EM : </tag.TaskDescEmEcHeading>Kushdeep
              Walia
            </tag.TaskDescAbout>
            <tag.TaskDescAbout>
              <tag.TaskDescEmEcHeading>EC : </tag.TaskDescEmEcHeading>Divjot
              Kaur , Harleen Kaur
            </tag.TaskDescAbout>
          </tag.TaskDescDescContainer>
        </tag.TaskDescEmEcContainer>
      </tag.TaskDescContainer>
      <tag.TaskContainer>
        <tag.TaskHeadingContainer>
          <tag.TaskHeading>Pending Tasks</tag.TaskHeading>
        </tag.TaskHeadingContainer>
        <tag.TaskListTableRow>
          <tag.TaskListTableHead>Task Name</tag.TaskListTableHead>
          <tag.TaskListTableHead>Complete By</tag.TaskListTableHead>
          <tag.TaskListTableHead>Assigned To</tag.TaskListTableHead>
          <tag.TaskListTableHead>Status</tag.TaskListTableHead>
          <tag.TaskListTableHead>&nbsp;</tag.TaskListTableHead>
        </tag.TaskListTableRow>
        <tag.TaskListTable>
          {PendingTasks.map((task, key) => {
            return (
              <tag.TaskListTableRow key={key++}>
                <tag.TaskListTableCell>{task.taskName}</tag.TaskListTableCell>
                <tag.TaskListTableCell>{task.completeBy}</tag.TaskListTableCell>
                <tag.TaskListTableCell>{task.assignedTo}</tag.TaskListTableCell>
                <tag.TaskListTableCell>
                  <tag.TaskStatusInput
                    type="checkbox"
                    name="pending"
                    id="pending"
                  />{" "}
                  Pending
                </tag.TaskListTableCell>
                <tag.TaskListTableCell>
                  <img src={del} alt="Delete Icon" />
                </tag.TaskListTableCell>
              </tag.TaskListTableRow>
            );
          })}
        </tag.TaskListTable>
      </tag.TaskContainer>
      <tag.TaskContainer>
        <tag.TaskHeadingContainer>
          <tag.TaskHeading>Completed Tasks</tag.TaskHeading>
        </tag.TaskHeadingContainer>
        <tag.TaskListTableRow>
          <tag.TaskListTableHead>Task Name</tag.TaskListTableHead>
          <tag.TaskListTableHead>Complete By</tag.TaskListTableHead>
          <tag.TaskListTableHead>Assigned To</tag.TaskListTableHead>
          <tag.TaskListTableHead>Status</tag.TaskListTableHead>
          <tag.TaskListTableHead>&nbsp;</tag.TaskListTableHead>
        </tag.TaskListTableRow>
        <tag.TaskListTable border={1}>
          {CompletedTasks.map((task, key) => {
            return (
              <tag.TaskListTableRow key={key++}>
                <tag.TaskListTableCell>{task.taskName}</tag.TaskListTableCell>
                <tag.TaskListTableCell>{task.completeBy}</tag.TaskListTableCell>
                <tag.TaskListTableCell>{task.assignedTo}</tag.TaskListTableCell>
                <tag.TaskListTableCell>
                  <tag.TaskStatusInput
                    type="checkbox"
                    name="pending"
                    id="pending"
                    checked
                    readOnly
                  />{" "}
                  Completed
                </tag.TaskListTableCell>
                <tag.TaskListTableCell>
                  <img src={del} alt="Delete Icon" />
                </tag.TaskListTableCell>
              </tag.TaskListTableRow>
            );
          })}
        </tag.TaskListTable>
      </tag.TaskContainer>
    </tag.TaskBoxContainer>
  );
};

export default TaskComponent;
