import styled from "styled-components";

export const Button = styled.div`
   border: 0.5px solid;
   width: 100%;
   padding: 0.45rem 0;
   display: flex;
   height: 35%;
   align-items: center;
   justify-content: center;
   cursor: pointer;
`