import React from "react";
import * as tag from "./Funds.style";
import Transcation from "../../components/Funds/AddTranscationComponent/Transcation.component";
import PostContribution from "../../components/Funds/PostContribution/PostContribution.Component";
import fundPage from "../../assets/fundPage.png";
import MemberComponent from "../../components/Organization/Member/Member.Component";

const Funds = () => {
  const list = [
    {
      id: 100,
      name: "Kushdeep Singh",
      mobno: "Credited",
      pos: "Rs 500",
    },
    {
      id: 101,
      name: "Jasveen Kaur",
      mobno: "Credited",
      pos: "Rs 500",
    },
    {
      id: 102,
      name: "Jasmeen Kaur",
      mobno: "Credited",
      pos: "Rs 500",
    },
    {
      id: 103,
      name: "Harleen Kaur",
      mobno: "Credited",
      pos: "Rs 500",
    },
    {
      id: 104,
      name: "Divjot Kaur",
      mobno: "Credited",
      pos: "Rs 500",
    },
    {
      id: 105,
      name: "Ishvinder Singh",
      mobno: "Credited",
      pos: "Rs 500",
    },
    {
      id: 106,
      name: "Jasjeet Singh",
      mobno: "Debited",
      pos: "Rs 2500",
    },
    {
      id: 107,
      name: "Jaskeerat Singh",
      mobno: "Credited",
      pos: "Rs 400",
    },
    {
      id: 108,
      name: "Kashish Bagga",
      mobno: "Debited",
      pos: "Rs 400",
    },
    {
      id: 109,
      name: "Mankirat Kaur",
      mobno: "Credited",
      pos: "Rs 400",
    },
    {
      id: 110,
      name: "Kushdeep Singh",
      mobno: "Debited",
      pos: "Rs 1500",
    },
    {
      id: 111,
      name: "Jasmeen Kaur",
      mobno: "Debited",
      pos: "Rs 200",
    },
    {
      id: 112,
      name: "Kashish",
      mobno: "Credited",
      pos: "Rs 3500",
    },
    {
      id: 113,
      name: "Divjot Kaur",
      mobno: "Debited",
      pos: "Rs 2000",
    },
    {
      id: 114,
      name: "Harleen Kaur",
      mobno: "Debited",
      pos: "Rs 500",
    },
    {
      id: 115,
      name: "Ishvinder Singh",
      mobno: "Credited",
      pos: "Rs 1000",
    },
  ];

  return (
    <tag.FundContainer>
      <tag.FundSubContainer>
        <tag.FundAssetEventContainer>
          <tag.FundAssetContainer>
            <Transcation />
          </tag.FundAssetContainer>
          <tag.FundEventContainer>
            <PostContribution />
          </tag.FundEventContainer>
        </tag.FundAssetEventContainer>
        <tag.FundMemberContainer>
          <MemberComponent
            list={list}
            icon={fundPage}
            heading="Payment History"
            resHeigh="650px;"
            widt="93%"
          />
        </tag.FundMemberContainer>
      </tag.FundSubContainer>
    </tag.FundContainer>
  );
};

export default Funds;
