import React from 'react'
import { Button, Span, ScheContainer } from './ScheduleMeet.style'

const ScheduleMeet = () => {
   return (
      <ScheContainer>
         <Span>Create a new meet</Span>
         <Button>Create</Button>
      </ScheContainer>
   )
}

export default ScheduleMeet
