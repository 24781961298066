import styled from "styled-components";

export const FundContainer = styled.div`
   width: 100%;
   height: 100%;
   background-color: white;
   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
   border-radius: 5px;

   display: flex;
   justify-content: center;
   align-items: center;
`

export const FundAvatarContainer = styled.div`
   width: 30%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
`

export const FundAvatar = styled.img`
   
`

export const FundTextContainer = styled.div`
   width: 70%;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
`

export const FundHeadingContainer = styled.div`
   width: 100%;
   margin-bottom: 0.5rem;
`

export const FundHeading = styled.h2`
   font-size: 2rem;
   font-weight: bold;
`

export const FundDescContainer = styled.div`
   
`

export const FundDesc = styled.span`
   
`

export const FundArrowContainer = styled.div`
   display: flex;
   justify-content: flex-end;
   padding-right: 3rem;
`

export const FundArrow = styled.img`
   
`