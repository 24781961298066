import React from "react";
import * as tag from "./Meeting.Style";
import MeetOrgPage from "../../../assets/meetOrgPage.png";
import MeetArrow from "../../../assets/rightArrow.png";

const MeetingComponent = () => {
  return (
    <tag.MeetContainer>
      <tag.MeetAvatarContainer>
        <tag.MeetAvatar src={MeetOrgPage} />
      </tag.MeetAvatarContainer>
      <tag.MeetTextContainer>
        <tag.MeetHeadingContainer>
          <tag.MeetHeading>Meeting</tag.MeetHeading>
          <tag.MeetDescContainer>
            <tag.MeetDesc>Team Meeting</tag.MeetDesc>
            <tag.MeetTimeStamp>
              Friday, Jan 14, 11:00am - 12:30pm
            </tag.MeetTimeStamp>
            <tag.MeetLink>meet.google.com/mbg-cuaj-cmt</tag.MeetLink>
          </tag.MeetDescContainer>
        </tag.MeetHeadingContainer>
        <tag.MeetHeadingContainer>
          <tag.MeetHeading>Attendance</tag.MeetHeading>
          <tag.MeetDescContainer>
            <tag.MeetDesc>Attended 5 meets out of 8</tag.MeetDesc>
            <tag.MeetLink></tag.MeetLink>
          </tag.MeetDescContainer>
        </tag.MeetHeadingContainer>
        <tag.MeetArrowContainer>
          <tag.MeetArrow src={MeetArrow} />
        </tag.MeetArrowContainer>
      </tag.MeetTextContainer>
    </tag.MeetContainer>
  );
};

export default MeetingComponent;
